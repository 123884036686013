// src/components/chat/ChatInterface.tsx

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatMessage from './ChatMessage';
import TalkInterface from './TalkInterface';
import StatusIndicator from './StatusIndicator';
import useChat from './hooks/useChat';
import Grid2 from '@mui/material/Grid2';
import SendIcon from '@mui/icons-material/Send';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useLanguage } from './LanguageContext';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './ChatInterface.css';
import { CurrentUser } from '../../models/interfaces';
import { useConfirmation } from '../../contexts/ConfirmationContext';
import axios from 'axios';

interface Message {
  content: string;
  role: string;
  question_name?: string;
  progress?: string;
  _id?: string;
}

interface Session {
  session_name: string;
  session_id: string;
  chatHistory: Message[];
  playAudio: boolean;
  _id: string;
}

interface ChatInterfaceProps {
  sessionNumber: number;
  lastInteraction: Session | null;
  getChatContext: () => any;
  onOpenFilters: () => void;
  user: CurrentUser | null;
  onApplyFilters: (filters: any) => void;
  filters: any;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({
  sessionNumber,
  lastInteraction,
  getChatContext,
  onOpenFilters,
  user,
  onApplyFilters,
  filters,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const {
    messages,
    inputText,
    setInputText,
    playAudio,
    togglePlayAudio,
    sendMessage,
    deleteMessage,
    editMessage,
    audioUrl,
    audioText,
    voiceRecordingFinish,
    getLanguage,
    voiceFinalTranscriptUpdate,
    voiceListeningStopped,
    getCurrentText,
    updateShowTalkLegend,
    clearAudioURL,
    talkStatusUpdate,
    inFlightRequest,
    submitCount,
    talkStatus,
    clearAllMessages,
  } = useChat({
    sessionNumber,
    lastInteraction,
    language,
    debug: true, // Enable debugging if needed
    getChatContext,
    onOpenFilters,
    user,
    onApplyFilters,
    filters,
  });

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { confirm } = useConfirmation();

  // State for minimize functionality
  const [isMinimized, setIsMinimized] = useState<boolean>(false);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputText.trim() !== '') {
      sendMessage(inputText);
      setInputText('');
    }
  };

  const isUserLastMessage = (index: number) => {
    const isLast = messages.length > 0 && index === messages.length - 2 && messages[index].role === 'user';
    return isLast;
  };

  const handleClearAll = async () => {
    const isConfirmed = await confirm({
      title: t('chat.confirmation'),
      description: t('chat.clearAllConfirmation'),
      confirmText: t('chat.confirm'),
      cancelText: t('chat.cancel'),
    });

    if (isConfirmed) {
      clearAllMessages();
      axios.post('/api/armond/save_interaction', {
        chatHistory: [],
        question: 'unkown',
        playAudio: '',
        language: '',
        chatContext: {},
        sessionNumber: sessionNumber,
      });
    }
  };

  const changeWindowToMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  return (
    <div className={`chat-container ${isMinimized ? 'minimized' : ''}`}>
      <div className="status-indicator-container">
        <div className="chat-controls">
          <Grid2 size="auto" container alignItems="center" spacing={{ sm: 1, md: 2 }}>
            <Grid2>
              <StatusIndicator status={talkStatus} />
            </Grid2>
            <Grid2>
              <TalkInterface
                voiceRecordingFinish={voiceRecordingFinish}
                getLanguage={getLanguage}
                voiceFinalTranscriptUpdate={voiceFinalTranscriptUpdate}
                voiceListeningStopped={voiceListeningStopped}
                getCurrentText={getCurrentText}
                audioUrl={audioUrl}
                audioText={audioText}
                voiceAllowedToPlay={playAudio}
                updateShowTalkLegend={updateShowTalkLegend}
                clearAudioURL={clearAudioURL}
                talkStatusUpdate={talkStatusUpdate}
                inFlightRequest={inFlightRequest}
                submitCount={submitCount}
              />
            </Grid2>
            <Grid2>
              <button
                onClick={togglePlayAudio}
                className={`chat-control-button play-button ${playAudio ? 'playing' : 'off'}`}
                title={playAudio ? t('chat.voiceOnHover') : t('chat.voiceOffHover')}
                aria-label={playAudio ? t('chat.voiceOn') : t('chat.voiceOff')}
              >
                {playAudio ? <VolumeUpIcon /> : <VolumeOffIcon />}
                <span className="tooltip">{playAudio ? t('chat.voiceOnHover') : t('chat.voiceOffHover')}</span>
              </button>
            </Grid2>
            <Grid2>
              <button
                onClick={handleClearAll}
                className="chat-control-button clear-all-button"
                title={t('chat.clearAllHover')}
                aria-label={t('chat.clearAll')}
              >
                <ClearAllIcon />
                <span className="tooltip">{t('chat.clearAllHover')}</span>
              </button>
            </Grid2>
            <Grid2>
              <button
                onClick={changeWindowToMinimize}
                className="chat-control-button minimize-button"
                title={isMinimized ? t('chat.restoreHover') : t('chat.minimizeHover')}
                aria-label={isMinimized ? t('chat.restore') : t('chat.minimize')}
              >
                {isMinimized ? <ExpandLessIcon /> : <MinimizeIcon />}
                <span className="tooltip">{isMinimized ? t('chat.restoreHover') : t('chat.minimizeHover')}</span>
              </button>
            </Grid2>
          </Grid2>
        </div>
      </div>

      {!isMinimized ? (
        <>
          <div className="chat-messages-container">
            <div className="chat-messages">
              {messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  onDelete={() => deleteMessage(index)}
                  onEdit={() => editMessage(index)}
                  isLatestUserMessage={isUserLastMessage(index)}
                  index={index}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <form className="chat-input" onSubmit={handleSubmit}>
            <textarea
              placeholder={t('chat.typeHere')}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  handleSubmit(e);
                }
              }}
              dir={language === 'he-IL' ? 'rtl' : 'ltr'}
              rows={2} /* Adjusted to 2 lines */
            />
            <button type="submit" className="send-button" aria-label="Send">
              <SendIcon />
            </button>
          </form>
        </>
      ) : (
        <div className="chat-minimze-empty"></div>
      )}
    </div>
  );
};

export default ChatInterface;
