// src/components/chat/StatusIndicator.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import { Mic, MicOff, VolumeUp, Send, ErrorOutline, Hearing } from '@mui/icons-material';

interface StatusIndicatorProps {
  status: string;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation();

  let icon = null;
  let tooltip = '';
  let showProgress = false;

  switch (status) {
    case 'getting-audio':
      icon = <Mic />;
      tooltip = t('talkInterface.status.getting-audio');
      showProgress = true;
      break;
    case 'playing-audio':
      icon = <VolumeUp />;
      tooltip = t('talkInterface.status.playing-audio');
      showProgress = true;
      break;
    case 'sending-message':
      icon = <Send />;
      tooltip = t('talkInterface.status.sending-message');
      showProgress = true;
      break;
    case 'failed-sending-message':
      icon = <ErrorOutline color="error" />;
      tooltip = t('talkInterface.status.failed-sending-message');
      break;
    case 'start-listening':
      icon = <Hearing />;
      tooltip = t('talkInterface.status.start-listening');
      break;
    case 'stop-listening':
      icon = <MicOff />;
      tooltip = t('talkInterface.status.stop-listening');
      break;
    default:
      icon = <MicOff />;
      tooltip = t('talkInterface.status.default');
      break;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton color="primary">
        {icon}
        {showProgress && <CircularProgress size={24} style={{ position: 'absolute', zIndex: 1 }} />}
      </IconButton>
    </Tooltip>
  );
};

export default StatusIndicator;
