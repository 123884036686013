// src/serviceWorker.ts

/* eslint-disable no-restricted-globals */

// Function to unregister the service worker
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
        console.log('Service Worker unregistered successfully.');
      })
      .catch((error) => {
        console.error('Service Worker unregistration failed:', error);
      });
  }
}

// Optionally, you can leave the register function empty or remove it
export function register() {
  // Intentionally left empty to disable service worker registration
}
