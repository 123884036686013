// src/components/add-auction/fields/jewelryFields.ts

export const calculateTitleForJewelry = (productFields: any): string => {
  const {
    fineness,
    material,
    jewelryType,
    totalCaratWeight,
    mainStoneType,
    colorType,
    color,
    fancyColorIntensity,
    fancyColorOvertone,
    fancyColorMainStone,
    mainStone,
  } = productFields;

  let titleParts = [];

  // Fineness
  if (fineness) {
    titleParts.push(fineness);
  }

  // Metal (Material)
  if (material) {
    titleParts.push(material.toLowerCase());
  }

  // Object Type (Jewelry Type)
  if (jewelryType) {
    titleParts.push(jewelryType);
  }

  // Total Carat Weight
  if (totalCaratWeight) {
    titleParts.push(`${totalCaratWeight}tcw`);
  }

  // Main Stone Type
  if (mainStoneType) {
    titleParts.push(mainStoneType);
  }

  // Color / Natural Colored
  if (colorType === 'Natural' && color) {
    titleParts.push(`${color} Color`);
  } else if (colorType === 'Colored') {
    const fancyColor = [fancyColorIntensity, fancyColorOvertone, fancyColorMainStone].filter(Boolean).join(' ');
    if (fancyColor) {
      titleParts.push(`Fancy ${fancyColor}`);
    }
  }

  // Main Stone
  if (mainStone) {
    titleParts.push(mainStone);
  }

  return titleParts.join(' ');
};

export const jewelryFields = [
  // Object Type: Ring, Earrings, Bracelet, Necklace
  {
    name: 'jewelryType',
    label: 'Jewelry Type',
    type: 'string',
    values: ['Ring', 'Earrings', 'Bracelet', 'Necklace'],
  },
  {
    name: 'material',
    label: 'Metal',
    type: 'select', // Changed to 'select' for predefined options
    values: ['Platinum', 'Rose Gold', 'White Gold', 'Yellow Gold'],
  },
  {
    name: 'fineness',
    label: 'Fineness',
    type: 'select',
    values: ['12K', '14K', '18K', '20K', '22K', '24K'],
  },
  {
    name: 'totalWeight',
    label: 'Total Weight (g)',
    type: 'number',
    values: [],
  },
  {
    name: 'condition',
    label: 'Condition',
    type: 'string',
    values: ['New', 'Used'],
  },
  {
    name: 'certification',
    label: 'Certification',
    type: 'string', // Clarify if it's a document upload or selection
    values: [],
  },
  {
    name: 'certificationNumber',
    label: 'Certification Number',
    type: 'string',
    values: [],
  },
  {
    name: 'totalCaratWeight',
    label: 'Total Carat Weight (g)',
    type: 'number', // Corrected type from 'numbe' to 'number'
    values: [],
  },
  {
    name: 'mainStone',
    label: 'Main Stone',
    type: 'select', // Assuming selection from catalog
    values: [
      'Alexandrite',
      'Amber',
      'Amethyst',
      'Ametrine',
      'Ammolite',
      'Apatite',
      'Aquamarine',
      'Bakelite',
      'Beryl',
      'Blood Coral',
      'Carnelian',
      "Cat's Eye (Chrysoberyl)",
      'Chalcedony',
      'Chrysoberyl',
      'Citrine',
      'Coral',
      'Danburite',
      'Demantoid',
      'Diamond',
      'Diaspore',
      'Emerald',
      'Enstatite',
      'Fire Opal',
      'Fluorite',
      'Garnet',
      'Grandidierite',
      'Hackmanite',
      'Heliodor',
      'Hessonite',
      'Hiddenite',
      'Idocrase',
      'Iolite',
      'Jade',
      'Kunzite',
      'Kyanite',
      'Lapis lazuli',
      'Mixed gemstones',
      'Morganite',
      'Onyx',
      'Opal',
      'Pearl',
      'Peridot',
      'Prehnite',
      'Quartz',
      'Rhodolite',
      'Ruby',
      'Sapphire',
      'Sapphire padparadscha',
      'Scapolite',
      'Sinhalite',
      'Smithsonite',
      'Spessartine',
      'Sphalerite',
      'Sphene',
      'Spinel',
      'Spodumene',
      'Star Ruby',
      'Star Sapphire',
      'Synthetic gemstone',
      'Tanzanite',
      'Topaz',
      'Tourmaline',
      'Tourmaline paraiba',
      'Tsavorite',
      'Turquoise',
      'Yellow Beryl (Heliodor)',
      'Zircon',
      'Zoisite',
      'Other',
    ],
  },
  {
    name: 'mainStoneType',
    label: 'Main Stone Type',
    type: 'select',
    values: ['Natural', 'Clarity Enhanced', 'Color Enhanced', 'Lab Grown'],
  },

  {
    name: 'colorType',
    label: 'Color Type',
    type: 'select',
    values: ['Natural', 'Colored'],
  },
  {
    name: 'color',
    label: 'Color',
    type: 'select', // Assuming selection from catalog
    values: ['D (colourless)', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N (tinted)', 'I-M (not certified)'], // Populate dynamically from Catalog
    dependentOn: { name: 'colorType', value: 'Colored' }, // Optional: Add dependency
  },

  {
    name: 'fancyColorIntensity',
    label: 'Fancy Color Intensity of Main Stone',
    type: 'select', // Assuming predefined levels
    values: [
      'Faint',
      'Fancy',
      'Fancy dark',
      'Fancy deep',
      'Fancy intense',
      'Fancy light',
      'Fancy vivid',
      'Light',
      'Very light',
    ],
    dependentOn: { name: 'colorType', value: 'Natural' }, // Optional: Add dependency
  },
  {
    name: 'fancyColorOvertone',
    label: 'Fancy Color Overtone of Main Stone',
    type: 'select',
    values: [
      'Blue',
      'Bluish',
      'Brown',
      'Brownish',
      'Green',
      'Greenish',
      'Grey',
      'Greyish',
      'No overtone',
      'Orange',
      'Orangy',
      'Pink',
      'Pinkish',
      'Purple',
      'Purplish',
      'Red',
      'Reddish',
      'Violet',
      'Violetish',
      'Yellow',
      'Yellowish',
    ],
    dependentOn: { name: 'colorType', value: 'Natural' }, // Optional: Add dependency
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'fancyColorMainStone',
    label: 'Fancy Color of Main Stone',
    type: 'select',
    values: [
      'Black',
      'Blue',
      'Brown',
      'Green',
      'Grey',
      'Mixed brown',
      'Mixed colours',
      'Mixed orange',
      'Mixed pink',
      'Mixed yellow',
      'Orange',
      'Pink',
      'Purple',
      'Red',
      'Violet',
      'White',
      'Yellow',
    ],
    dependentOn: { name: 'colorType', value: 'Natural' }, // Optional: Add dependency
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'mainStoneCaratWeight',
    label: 'Main Stone Carat Weight',
    type: 'number',
    values: [],
  },
  {
    name: 'numberOfMainStones',
    label: 'Number Of Main Stones (Optional)',
    type: 'number',
    values: [],
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'clarityGrade',
    label: 'Diamond Clarity Of Main Stone',
    type: 'text',
    values: [
      'FL',
      'IF',
      'VVS1',
      'VVS2',
      'VS1',
      'VS2',
      'SI1',
      'SI2',
      'SI3',
      'I1',
      'I2',
      'I3',
      'Not specified in lab report',
    ],
  },
  {
    name: 'shape',
    label: 'Shape of Main Stone',
    type: 'select',
    values: [
      'Bead',
      'Bullet',
      'Calf',
      'Cushion',
      'Cut cornered square',
      'Drop',
      'Emerald',
      'Fancy',
      'Fantasy',
      'Half moon',
      'Heart',
      'Kite',
      'Lozenge',
      'Marquise',
      'Mixed shape',
      'Octagon',
      'Oval',
      'Pear',
      'Princess',
      'Radiant',
      'Rectangle',
      'Round',
      'Shield',
      'Square',
      'Star',
      'Tapered',
      'Trapezoid',
      'Triangle',
    ],
  },
  {
    name: 'surroundingStones',
    label: 'Surrounding Stones (Optional)',
    type: 'select', // Assuming selection from catalog
    values: [
      'Alexandrite',
      'Amber',
      'Amethyst',
      'Ametrine',
      'Ammolite',
      'Apatite',
      'Aquamarine',
      'Bakelite',
      'Beryl',
      'Blood Coral',
      'Carnelian',
      "Cat's Eye (Chrysoberyl)",
      'Chalcedony',
      'Chrysoberyl',
      'Citrine',
      'Coral',
      'Danburite',
      'Demantoid',
      'Diamond',
      'Diaspore',
      'Emerald',
      'Enstatite',
      'Fire Opal',
      'Fluorite',
      'Garnet',
      'Grandidierite',
      'Hackmanite',
      'Heliodor',
      'Hessonite',
      'Hiddenite',
      'Idocrase',
      'Iolite',
      'Jade',
      'Kunzite',
      'Kyanite',
      'Lapis lazuli',
      'Mixed gemstones',
      'Morganite',
      'Onyx',
      'Opal',
      'Pearl',
      'Peridot',
      'Prehnite',
      'Quartz',
      'Rhodolite',
      'Ruby',
      'Sapphire',
      'Sapphire padparadscha',
      'Scapolite',
      'Sinhalite',
      'Smithsonite',
      'Spessartine',
      'Sphalerite',
      'Sphene',
      'Spinel',
      'Spodumene',
      'Star Ruby',
      'Star Sapphire',
      'Synthetic gemstone',
      'Tanzanite',
      'Topaz',
      'Tourmaline',
      'Tourmaline paraiba',
      'Tsavorite',
      'Turquoise',
      'Yellow Beryl (Heliodor)',
      'Zircon',
      'Zoisite',
      'Other',
    ],
  },
  {
    name: 'surroundingStoneType',
    label: 'Surrounding Stone Type',
    type: 'select',
    values: ['Natural', 'Clarity Enhanced', 'Color Enhanced', 'Lab Grown'],
  },
  {
    name: 'surroundingStoneCaratWeight',
    label: 'Surrounding Stone Carat Weight',
    type: 'number',
    values: [],
  },
  {
    name: 'numberOfSurroundingStones',
    label: 'Number Of Surrounding Stones (Optional)',
    type: 'number',
    values: [],
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'surroundingStoneClarityGrade',
    label: 'Diamond Clarity Of Surrounding Stone',
    type: 'text',
    values: [
      'FL',
      'IF',
      'VVS1',
      'VVS2',
      'VS1',
      'VS2',
      'SI1',
      'SI2',
      'SI3',
      'I1',
      'I2',
      'I3',
      'Not specified in lab report',
    ],
  },
  {
    name: 'surroundingStoneShape',
    label: 'Shape of Surrounding Stone',
    type: 'select',
    values: [
      'Bead',
      'Bullet',
      'Calf',
      'Cushion',
      'Cut cornered square',
      'Drop',
      'Emerald',
      'Fancy',
      'Fantasy',
      'Half moon',
      'Heart',
      'Kite',
      'Lozenge',
      'Marquise',
      'Mixed shape',
      'Octagon',
      'Oval',
      'Pear',
      'Princess',
      'Radiant',
      'Rectangle',
      'Round',
      'Shield',
      'Square',
      'Star',
      'Tapered',
      'Trapezoid',
      'Triangle',
    ],
  },

  {
    name: 'length',
    label: 'Length (cm)',
    type: 'number',
    values: [],
  },
  {
    name: 'diameter',
    label: 'Diameter (cm)',
    type: 'number',
    values: [],
  },
  {
    name: 'width',
    label: 'Width (cm)',
    type: 'number',
    values: [],
  },
  {
    name: 'height',
    label: 'Height (cm)',
    type: 'number',
    values: [],
  },
  {
    name: 'circumference',
    label: 'Circumference (cm)',
    type: 'number',
    values: [],
  },
  {
    name: 'ringSize',
    label: 'Ring Size',
    type: 'select',
    values: [
      '44 (EU) / 4 (IT) / 3 (US)',
      '45 (EU) / 5 (IT) / 3¼ (US)',
      '46 (EU) / 6 (IT) / 3¾ (US)',
      '47 (EU) / 7 (IT) / 4 (US)',
      '48 (EU) / 8 (IT) / 4½ (US)',
      '49 (EU) / 9 (IT) / 4¾ (US)',
      '50 (EU) / 10 (IT) / 5¼ (US)',
      '51 (EU) / 11 (IT) / 5¾ (US)',
      '52 (EU) / 12 (IT) / 6 (US)',
      '53 (EU) / 13 (IT) / 6¼ (US)',
      '54 (EU) / 14 (IT) / 6¾ (US)',
      '55 (EU) / 15 (IT) / 7¼ (US)',
      '56 (EU) / 16 (IT) / 7½ (US)',
      '57 (EU) / 17 (IT) / 8 (US)',
      '58 (EU) / 18 (IT) / 8¼ (US)',
      '59 (EU) / 19 (IT) / 8¾ (US)',
      '60 (EU) / 20 (IT) / 9 (US)',
      '61 (EU) / 21 (IT) / 9½ (US)',
      '62 (EU) / 22 (IT) / 10 (US)',
      '63 (EU) / 23 (IT) / 10¼ (US)',
      '64 (EU) / 24 (IT) / 10¾ (US)',
      '65 (EU) / 25 (IT) / 11 (US)',
      '66 (EU) / 26 (IT) / 11½ (US)',
      '67 (EU) / 27 (IT) / 11¾ (US)',
      '68 (EU) / 28 (IT) / 12¼ (US)',
      '69 (EU) / 29 (IT) / 12½ (US)',
      '70 (EU) / 30 (IT) / 13 (US)',
      '71 (EU) / 31 (IT) / 13¼ (US)',
      'Adjustable',
    ],
  },
  {
    name: 'braceletSize',
    label: 'Bracelet Size',
    type: 'select',
    values: [
      'Extra Extra Large (>20 cm)',
      'Extra Large (19.5 - 20 cm)',
      'Large (19 cm)',
      'Large (18 - 18.5 cm)',
      'Medium (17 - 18 cm)',
      'Medium (18 - 18.5 cm)',
      'Small (16.5 - 17 cm)',
      'Extra Small (15 - 16 cm)',
    ],
  },

  // Additional Information
  {
    name: 'size',
    label: 'Size',
    type: 'number',
    unit: 'cm', // Optional: Add unit for clarity
    values: [],
  },

  // Optional Fields
  {
    name: 'titleAdditionalInfo',
    label: 'Title Additional Information (Optional)',
    type: 'string',
    values: [],
  },
];