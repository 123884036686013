// src/components/add-auction/ImageUploadSection.tsx

import React from 'react';
import { Grid2, IconButton, Typography } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

interface ImageUploadSectionProps {
  coverImage: File | null;
  existingCoverImage: string;
  setCoverImage: (file: File | null) => void;
  imageFiles: File[];
  existingImages: string[];
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage: (index: number) => void;
  handleRemoveExistingImage: (index: number) => void;
}

const ImageUploadSection: React.FC<ImageUploadSectionProps> = ({
  coverImage,
  existingCoverImage,
  setCoverImage,
  imageFiles,
  existingImages,
  handleImageChange,
  handleRemoveImage,
  handleRemoveExistingImage,
}) => {
  // Dropzone for Cover Image
  const {
    getRootProps: getCoverRootProps,
    getInputProps: getCoverInputProps,
    isDragActive: isCoverDragActive,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles: File[]) => {
      // Assuming only one cover image
      if (acceptedFiles.length > 0) {
        setCoverImage(acceptedFiles[0]);
      }
    },
  });

  // Dropzone for Additional Images
  const {
    getRootProps: getImagesRootProps,
    getInputProps: getImagesInputProps,
    isDragActive: isImagesDragActive,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles: File[]) => {
      const event = {
        target: {
          files: acceptedFiles,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      handleImageChange(event);
    },
  });

  return (
    <>
      {/* Cover Image */}
      <Grid2 container spacing={12} alignItems="center" style={{ marginBottom: '20px' }}>
        <div>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Upload Cover Image
          </Typography>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2>
              <div
                {...getCoverRootProps()}
                style={{
                  border: '2px dashed #cccccc',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '8px',
                }}
              >
                <input {...getCoverInputProps()} />
                <IconButton color="primary" aria-label="upload cover image">
                  <PhotoCamera />
                </IconButton>
                {isCoverDragActive ? (
                  <Typography>Drop the cover image here...</Typography>
                ) : (
                  <Typography>
                    Drag 'n' drop cover image here,
                    <br />
                    or click to select files
                  </Typography>
                )}
              </div>
            </Grid2>
            {(coverImage || existingCoverImage) && (
              <Grid2>
                <div style={{ position: 'relative' }}>
                  <img
                    src={coverImage ? URL.createObjectURL(coverImage) : existingCoverImage}
                    alt="Cover"
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                    }}
                  />
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      color: 'red',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                    onClick={() => setCoverImage(null)}
                  >
                    &times;
                  </IconButton>
                </div>
              </Grid2>
            )}
          </Grid2>
        </div>
        {/* Additional Images */}
        <div>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Upload Images
          </Typography>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2>
              <div
                {...getImagesRootProps()}
                style={{
                  border: '2px dashed #cccccc',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '8px',
                }}
              >
                <input {...getImagesInputProps()} />
                <IconButton color="primary" aria-label="upload pictures">
                  <PhotoCamera />
                </IconButton>
                {isImagesDragActive ? (
                  <Typography>Drop the images here...</Typography>
                ) : (
                  <Typography>
                    Drag 'n' drop images here,
                    <br />
                    or click to select files
                  </Typography>
                )}
              </div>
            </Grid2>
            {(imageFiles.length > 0 || existingImages.length > 0) && (
              <Grid2 container spacing={1} alignItems="center" wrap="nowrap" style={{ overflowX: 'auto' }}>
                {existingImages.map((imageUrl, index) => (
                  <Grid2 key={`existing-${index}`}>
                    <div style={{ position: 'relative' }}>
                      <img
                        src={imageUrl}
                        alt={`Existing ${index + 1}`}
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                        }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                          color: 'red',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        }}
                        onClick={() => handleRemoveExistingImage(index)}
                      >
                        &times;
                      </IconButton>
                    </div>
                  </Grid2>
                ))}
                {imageFiles.map((file, index) => (
                  <Grid2 key={`new-${index}`}>
                    <div style={{ position: 'relative' }}>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Selected ${index + 1}`}
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                        }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                          color: 'red',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        &times;
                      </IconButton>
                    </div>
                  </Grid2>
                ))}
              </Grid2>
            )}
          </Grid2>
        </div>
      </Grid2>
    </>
  );
};

export default ImageUploadSection;
