// src/theme.ts

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1890ff',
    },
    secondary: {
      main: '#f0f0f0',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Arial, Helvetica, sans-serif',
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#222',
    },
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          marginBottom: '8px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '8px 12px',
        },
        content: {
          margin: '0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 12px 8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          paddingLeft: '12px',
          fontSize: '16px',
          color: '#222',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          wordBreak: 'break-all',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#1890ff',
          '&.Mui-checked': {
            color: '#1890ff',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#1890ff',
          padding: '8px 0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#222',
          fontSize: '14px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#666666',
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e0e0',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1890ff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1890ff',
          },
        },
      },
    },
  },
});

export default theme;
