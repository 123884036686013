// src/components/users/EditUserPage.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Paper, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ProfileTabs from '../settings/ProfileTabs';
import { UserSettings } from '../../models/UserSettings';
import '../settings/styles/UserProfileSettingsPage.css';
import { CurrentUser } from '../../models/interfaces';

interface EditUserPageProps {
  loginUser: CurrentUser;
}

const EditUserPage: React.FC<EditUserPageProps> = ({ loginUser }) => {
  const { id } = useParams<{ id: string }>(); // Get user ID from the URL
  const [originalSettings, setOriginalSettings] = useState<UserSettings | null>(null);
  const [editedUserSettings, setEditedUserSettings] = useState<UserSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await axios.get(`/api/user/${id}`, {
          // Include authorization headers if necessary
        });

        const userData = response.data;

        const settings: UserSettings = {
          personalInfo: {
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            nickname: userData.nickname || '',
            dateJoined: userData.dateJoined || '',
          },
          contactInfo: {
            email: userData.email || '',
            phoneNumber: userData.phoneNumber || '',
          },
          addressInfo: {
            billingAddress: userData.billingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: userData.shippingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: userData.hobbies || '',
            receiveNewsletters: userData.receiveNewsletters || false,
            receivePromotions: userData.receivePromotions || false,
            receiveUpdates: userData.receiveUpdates || false,
          },
          accountDetails: {
            dateJoined: userData.dateJoined || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: userData.role,
        };

        setOriginalSettings(settings);
        setEditedUserSettings(settings);
      } catch (error) {
        console.error('Error fetching user settings:', error);
        alert('Failed to fetch user data.');
      }
    };

    fetchUserSettings();
  }, [id]);

  const handleSave = async () => {
    if (!editedUserSettings) return;
    setIsSaving(true);
    try {
      const settingsToUpdate = {
        personalInfo: editedUserSettings.personalInfo,
        contactInfo: editedUserSettings.contactInfo,
        addressInfo: editedUserSettings.addressInfo,
        preferences: editedUserSettings.preferences,
        role: editedUserSettings.role, // Include role in the update
      };

      await axios.put(`/api/user/${id}`, settingsToUpdate); // Ensure backend handles role update
      setOriginalSettings(editedUserSettings);
      alert('User settings saved successfully!');
    } catch (error) {
      console.error('Error saving user settings:', error);
      alert('Failed to save user settings.');
    } finally {
      setIsSaving(false);
    }
  };

  if (!editedUserSettings) {
    return (
      <Container className="loading-container">
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        Edit User Profile
      </Typography>
      <Paper elevation={3} className="paper-container">
        <ProfileTabs
          userSettings={editedUserSettings}
          setUserSettings={setEditedUserSettings as React.Dispatch<React.SetStateAction<UserSettings>>}
          originalSettings={originalSettings!}
        />

        {/* Conditional Role Field */}
        <FormControl fullWidth margin="normal" disabled={loginUser.role !== 'Admin'}>
          <InputLabel id="role-select-label">Role</InputLabel>
          {loginUser.role === 'Admin' ? (
            <Select
              labelId="role-select-label"
              id="role-select"
              value={editedUserSettings.role}
              label="Role"
              onChange={(e) =>
                setEditedUserSettings((prev) => (prev ? { ...prev, role: e.target.value as string } : prev))
              }
            >
              <MenuItem value="User">LoginUser</MenuItem>
              <MenuItem value="Supplier">Supplier</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              {/* Add more roles as needed */}
            </Select>
          ) : (
            <Typography variant="body1" style={{ padding: '14px 0 0 14px' }}>
              {editedUserSettings.role}
            </Typography>
          )}
        </FormControl>

        <div className="save-button-container">
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save All Changes'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginLeft: '10px' }}
            onClick={() => navigate('/users')}
          >
            Cancel
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default EditUserPage;
