// src/components/add-auction/fields/diamondFields.ts
// specification?

export const calculateTitleForDiamond = (productFields: any): string => {
  const { caratWeight, color, clarityGrade, cut, shape, certificate } = productFields;

  let titleParts = [];

  if (caratWeight) {
    titleParts.push(`${caratWeight}ct`);
  }

  if (color) {
    titleParts.push(color);
  }

  if (clarityGrade) {
    titleParts.push(clarityGrade);
  }

  if (cut) {
    titleParts.push(cut);
  }

  if (shape) {
    titleParts.push(shape);
  }

  if (certificate) {
    titleParts.push(`with ${certificate}`);
  }

  return titleParts.join(' ');
};

export const diamondFields = [
  {
    name: 'shape',
    label: 'Shape',
    type: 'text',
    values: [
      'Bead',
      'Bullet',
      'Calf',
      'Cushion',
      'Cut cornered square',
      'Drop',
      'Emerald',
      'Fancy',
      'Fantasy',
      'Half moon',
      'Heart',
      'Kite',
      'Lozenge',
      'Marquise',
      'Mixed shape',
      'Octagon',
      'Oval',
      'Pear',
      'Radiant',
      'Rectangle',
      'Round',
      'Shield',
      'Square',
      'Star',
      'Tapered',
      'Trapezoid',
      'Triangle',
    ],
  },
  {
    name: 'caratWeight',
    label: 'Carat Weight',
    type: 'number',
  },
  {
    name: 'colorGrade',
    label: 'Color Grade',
    type: 'text',
  },
  {
    name: 'clarityGrade',
    label: 'Clarity Grade',
    type: 'text',
    values: [
      'FL',
      'IF',
      'VVS1',
      'VVS2',
      'VS1',
      'VS2',
      'SI1',
      'SI2',
      'SI3',
      'I1',
      'I2',
      'I3',
      'Not specified in lab report',
    ],
  },
  {
    name: 'cutGrade',
    label: 'Cut Grade',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'certificateNumber',
    label: 'Certificate Number',
    type: 'text',
  },
  {
    name: 'measurements',
    label: 'Measurements',
    type: 'text',
  },
  {
    name: 'fluorescence',
    label: 'Fluorescence',
    type: 'text',
  },
  {
    name: 'polish',
    label: 'Polish',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'symmetry',
    label: 'Symmetry',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'depthPercentage',
    label: 'Depth Percentage',
    type: 'number',
  },
  {
    name: 'tablePercentage',
    label: 'Table Percentage',
    type: 'number',
  },
  {
    name: 'numberOfDiamonds',
    label: 'Number of Diamonds',
    type: 'number',
    values: [],
  },
  {
    name: 'naturalFancyColour',
    label: 'Natural Fancy Colour',
    type: 'text',
  },
  {
    name: 'fancyColourIntensity',
    label: 'Fancy Colour Intensity',
    type: 'text',
  },
  {
    name: 'laboratoryReport',
    label: 'Laboratory Report',
    type: 'text',
    values: [
      'Anchor Cert',
      'Antwerp Laboratory for Gemstone Testing (ALGT)',
      'De Beers Institute of Diamonds',
      'GEM-TECH Istituto Gemmologico',
      'GGTL Liechtenstein and Switzerland',
      'Gem Report Antwerp (GRA)',
      'Gemewizard Gemological Laboratory (GWLab)',
      'Gemological Institute of America (GIA)',
      'HRD Antwerp',
      'Instituto Gem\u00f3logico Espa\u00f1ol (IGE)',
      'International Gemological Institute (IGI)',
      'International Jewellery & Gems Certifiers (IJGC)',
      'Istituto Gemmologico Italiano (IGI)',
      'Laboratoire Fran\u00e7ais de Gemmologie (LFG)',
      'Masterstones',
      'Netherlands Gem Laboratory (NEL)',
      'No laboratory report',
    ],
  },
  {
    name: 'sealedByLaboratory',
    label: 'Sealed by Laboratory',
    type: 'text',
    values: [],
  },
  {
    name: 'laserEngraved',
    label: 'Laser Engraved',
    type: 'text',
    values: [],
  },
  {
    name: 'girdle',
    label: 'Girdle',
    type: 'text',
    values: [
      'Extremely thick',
      'Extremely thin',
      'Medium',
      'Slightly thick',
      'Thick',
      'Thin',
      'Very thick',
      'Very thin',
    ],
  },
  {
    name: 'diamondTypeMainStone',
    label: 'Diamond type',
    type: 'text',
    values: ['Colour-treated', 'Natural', 'Natural coloured'],
  },
  {
    name: 'totalCaratWeight',
    label: 'Total carat weight',
    type: 'text',
    values: [],
  },
  {
    name: 'cuttingStyle',
    label: 'Cutting style',
    type: 'text',
    values: [
      'Baguette cut',
      'Barion cut',
      'Brilliant cut',
      'Briolette cut',
      'Cabochon cut',
      'Carre cut',
      'Carving cut',
      'Emerald cut',
      'Faceted cut',
      'Mixed cut',
      'Modified brilliant cut',
      'Modified cut',
      'Old European cut',
      'Old mine cut',
      'Pear shape cut',
      'Princess cut',
      'Radiant cut',
      'Rose cut',
      'Scissor cut',
      'Single cut',
      'Squared emerald cut',
      'Step cut',
      'Table cut',
      'Trilliant cut',
    ],
  },
  {
    name: 'labReportNumber',
    label: 'Lab report number',
    type: 'text',
    values: [],
  },
  {
    name: 'weight',
    label: 'Weight',
    type: 'text',
    values: [
      '0.01-0.03',
      '0.04-0.07',
      '0.08-0.14',
      '0.15 - 0.17',
      '0.18-0.22',
      '0.23-0.29',
      '0.30-0.39',
      '0.40-0.49',
      '0.50-0.69',
      '0.70-0.89',
      '0.90-0.99',
      '1.00-1.49',
      '1.50-1.99',
      '2.00-2.99',
      '3.00-3.99',
      '4.00-4.99',
      '5.00-5.99',
      '>6.00',
    ],
  },
];
