// src/components/settings/ContactInfo.tsx

import React from 'react';
import { TextField, Grid } from '@mui/material';
import { UserSettings } from '../../models/UserSettings';
import './styles/ContactInfo.css';

interface ContactInfoProps {
  contactInfo: UserSettings['contactInfo'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalContactInfo: UserSettings['contactInfo'];
}

const ContactInfo: React.FC<ContactInfoProps> = ({ contactInfo, setUserSettings, originalContactInfo }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      contactInfo: {
        ...prev.contactInfo,
        [name]: value,
      },
    }));
  };

  const isChanged = (field: keyof UserSettings['contactInfo']) => {
    return contactInfo[field] !== originalContactInfo[field];
  };

  return (
    <form className="form-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            required
            value={contactInfo.email}
            onChange={handleChange}
            className={isChanged('email') ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            fullWidth
            variant="outlined"
            value={contactInfo.phoneNumber}
            onChange={handleChange}
            className={isChanged('phoneNumber') ? 'changed-field' : ''}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactInfo;
