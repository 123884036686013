// src/components/settings/ProfileTabs.tsx

import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import ContactInfo from './ContactInfo';
import AddressInfo from './AddressInfo';
import Preferences from './Preferences';
import AccountDetails from './AccountDetails';
import { UserSettings } from '../../models/UserSettings';
import './styles/ProfileTabs.css';

interface ProfileTabsProps {
  userSettings: UserSettings;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalSettings: UserSettings;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ userSettings, setUserSettings, originalSettings }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label="Personal Info" />
        <Tab label="Contact Info" />
        <Tab label="Addresses" />
        <Tab label="Preferences" />
        <Tab label="Account Details" />
      </Tabs>
      <Box p={3}>
        {value === 0 && (
          <PersonalInfo
            personalInfo={userSettings.personalInfo}
            setUserSettings={setUserSettings}
            originalPersonalInfo={originalSettings.personalInfo}
          />
        )}
        {value === 1 && (
          <ContactInfo
            contactInfo={userSettings.contactInfo}
            setUserSettings={setUserSettings}
            originalContactInfo={originalSettings.contactInfo}
          />
        )}
        {value === 2 && (
          <AddressInfo
            addressInfo={userSettings.addressInfo}
            setUserSettings={setUserSettings}
            originalAddressInfo={originalSettings.addressInfo}
          />
        )}
        {value === 3 && (
          <Preferences
            preferences={userSettings.preferences}
            setUserSettings={setUserSettings}
            originalPreferences={originalSettings.preferences}
          />
        )}
        {value === 4 && (
          <AccountDetails
            accountDetails={userSettings.accountDetails}
            setUserSettings={setUserSettings}
            originalAccountDetails={originalSettings.accountDetails}
          />
        )}
      </Box>
    </>
  );
};

export default ProfileTabs;
