// src/pages/HomePage.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid2, CircularProgress, Typography } from '@mui/material';
import { GetAuctionsResponse, AuctionItem as AuctionItemType } from '../models/interfaces';
import Categories from '../components/Categories';
import AuctionCardTransition from '../components/AuctionCardTransition';
import { useCookies } from 'react-cookie';
import './HomePage.css';
import { useWebSocket } from '../contexts/WebSocketContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import qs from 'qs';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { use } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Description } from '@mui/icons-material';
import { start } from 'repl';
import { is } from 'date-fns/locale';

interface HomePageProps {
  filters?: any; // Make filters optional with default value
  onLoginRequired: () => void;
  onApplyFilters: (filters: any) => void;
  onChatContext: (chatContext: any) => void;
}

const HomePage: React.FC<HomePageProps> = ({
  filters = {}, // Default to empty object
  onLoginRequired,
  onApplyFilters,
  onChatContext,
}) => {
  const [cookies] = useCookies(['armondai']);
  const [items, setItems] = useState<AuctionItemType[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Added error state
  const [hasScrolled, setHasScrolled] = useState(false); // Track scroll state
  const { category } = useParams<{ category: string }>();
  const { socket } = useWebSocket();
  const [title, setTitle] = useState<string>('Auction House');
  const location = useLocation();
  const navigate = useNavigate();
  const [supplierDetails, setSupplierDetails] = useState<any>({});

  const titleMap: { [key: string]: string } = {
    jewelry: 'Jewelry',
    watch: 'Watches',
    watches: 'Watches',
    gemstone: 'Gemstones',
    diamonds: 'Diamonds',
    diamond: 'Diamonds',
    myBids: 'My Bids',
    myFavourite: 'My Favorites',
  };

  // Utility function to sanitize filters
  const sanitizeFilters = (filters: any = {}) => {
    const { page, ...rest } = filters;
    return rest;
  };

  const fetchItems = async (appliedFilters: any, page: number = 1) => {
    if (isLoading) return; // Prevent multiple fetches

    setIsLoading(true);
    setError(null); // Reset error state

    const token = cookies.armondai;

    // Sanitize filters to exclude 'page', fallback to empty object if appliedFilters is null
    const restFilters = sanitizeFilters(appliedFilters || {});
    console.log('fetchItems called with filters:', restFilters, 'page:', page, 'statusFilter:', statusFilter);
    const params: any = {
      page, // Set the intended page
      limit: 20, // Adjust the limit as needed
    };

    if (statusFilter) {
      params.status = statusFilter;
    }

    if (restFilters) {
      Object.assign(params, restFilters);
    }

    try {
      console.log(`Fetching page: ${page}`); // Debugging log

      const response = await axios.get<GetAuctionsResponse>(`/api/auction-items/list`, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
        withCredentials: true,
      });

      if (response.data && Array.isArray(response.data.auctions)) {
        const fetchedCurrentPage =
          typeof response.data.currentPage === 'string' ? Number(response.data.currentPage) : response.data.currentPage;

        const fetchedTotalPages =
          typeof response.data.totalPages === 'string' ? Number(response.data.totalPages) : response.data.totalPages;

        if (isNaN(fetchedCurrentPage) || isNaN(fetchedTotalPages)) {
          console.error('Conversion error: currentPage or totalPages is NaN', response.data);
          setError('Received invalid pagination data from server.');
          return;
        }

        setItems((prevItems) => (page === 1 ? response.data.auctions : [...prevItems, ...response.data.auctions]));
        setCurrentPage(fetchedCurrentPage);
        setTotalPages(fetchedTotalPages);
        console.log(`Fetched page: ${fetchedCurrentPage} of ${fetchedTotalPages}`);
        console.log('Fetched items:', response.data.auctions);
        console.log('Current filters:', restFilters);
        updateChatContect(response.data.auctions);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Received unexpected data format from server.');
        if (page === 1) setItems([]);
      }
    } catch (error: any) {
      console.error('Error fetching auctions:', error);
      if (error.response && error.response.status === 401) {
        onLoginRequired();
      } else {
        setError('Failed to load auction items. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatContect = (auctions: AuctionItemType[]) => {
    const chatContext = auctions.map((auction) => {
      let chatContextItem = {
        id: auction._id,
        title: auction.product.title,
        description: auction.product.description,
        expertEstimateMax: auction.expertEstimateMax,
        expertEstimateMin: auction.expertEstimateMin,
        startDate: auction.startDate,
        endDate: auction.endDate,
        currentBid: auction.currentBid,
        startingPrice: auction.startingPrice,
        category: auction.category,
        isFavorite: auction.isFavorite,
      };
      return chatContextItem;
    });
    onChatContext({ list: chatContext });
  };

  // Function to fetch more data
  const fetchMoreData = () => {
    console.log('fetchMoreData called with filters:', filters, 'currentPage:', currentPage);
    if (currentPage >= totalPages || isLoading) return;
    fetchItems(filters || {}, currentPage + 1); // Fallback to empty object if filters is null
  };

  useEffect(() => {
    if (category) {
      setTitle(titleMap[category] || 'Auction House');
    }
  }, [category]);

  useEffect(() => {
    if (supplierDetails && supplierDetails.name) {
      setTitle(`Items From ${supplierDetails.name}` || 'Auction House');
    }
  }, [supplierDetails]);

  useEffect(() => {
    if (location.pathname.startsWith('/supplier/')) {
      const supplierId = location.pathname.split('/').pop();
      axios.get(`/api/suppliers/${supplierId}`).then((response) => {
        if (response.data) {
          setSupplierDetails(response.data);
        }
      });
    }
  }, [location]);

  // Initial and filter-based fetching
  useEffect(() => {
    console.log('useEffect triggered with filters:', filters, 'statusFilter:', statusFilter);
    // Reset to first page when filters change
    setItems([]);
    setCurrentPage(1);
    setTotalPages(1);
    fetchItems(filters, 1);
  }, [filters, statusFilter]);

  const handleCategorySelect = (category: string) => {
    console.log('handleCategorySelect called with category:', category);
    const updatedFilters = {
      ...filters,
      categories: [category],
    };
    console.log('Updated filters:', updatedFilters);

    setItems([]);
    setCurrentPage(1);
    setTotalPages(1);
    fetchItems(updatedFilters, 1);
    onApplyFilters({ filter: [category] });
    navigate(`/category/${category}`);
  };

  // Handle real-time bid updates
  useEffect(() => {
    if (socket) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('Received priceUpdated event:', data);
        setItems((prevItems) =>
          prevItems.map((item) => (item._id === data.auctionId ? { ...item, currentBid: data.newPrice } : item))
        );
      };

      // Listen for 'priceUpdated' events
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup the listener on unmount
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket]);

  // Track if the user has scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Swap items every 5 seconds if user hasn't scrolled
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null; // Explicitly type the interval

    if (!hasScrolled) {
      interval = setInterval(() => {
        if (window.scrollY === 0) {
          setItems((prevItems) => {
            if (prevItems.length >= 12) {
              const newItems = [...prevItems];
              const first6 = newItems.slice(0, 6);
              const next6 = newItems.slice(6, 12);
              newItems.splice(0, 12, ...next6, ...first6);
              return newItems;
            }
            return prevItems;
          });
        }
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [hasScrolled]);

  return (
    <div className="home-page">
      <Container className="categories-container">
        <div className="categories">
          <Categories onCategorySelect={handleCategorySelect} />
        </div>
      </Container>

      <Container className="auctions-container">
        <Typography variant="h3" align="left" gutterBottom>
          {title}
        </Typography>
        <div className="auctions">
          {error && (
            <Typography variant="body1" color="error" align="center">
              {error}
            </Typography>
          )}

          {/* Display "No auction items found." if not loading and no items */}
          {!isLoading && items.length === 0 && !error && (
            <Typography variant="body1" align="center">
              No auction items found.
            </Typography>
          )}

          {/* Only render InfiniteScroll if there are items or loading */}
          {(items.length > 0 || isLoading) && (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreData}
              hasMore={currentPage < totalPages && items.length > 0}
              loader={
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <CircularProgress />
                </div>
              }
              endMessage={
                items.length > 0 ? (
                  <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
                    No more items to load.
                  </Typography>
                ) : null
              }
              style={{ overflow: 'visible' }} // Prevent internal scrollbar
            >
              <Grid2 container spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
                <TransitionGroup component={null}>
                  {items.map((item) => (
                    <CSSTransition key={item._id} timeout={500} classNames="item">
                      <AuctionCardTransition key={item._id} item={item} />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Grid2>
            </InfiniteScroll>
          )}

          {/* Optionally, display loader outside InfiniteScroll if needed */}
          {isLoading && items.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default HomePage;
