// src/pages/AuctionItemPage.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Dialog, Card, CardContent } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { useCookies } from 'react-cookie'; // Import useCookies
import labels_en from '../localization/labels_en.json';
import {
  ProductTypes,
  Product,
  AuctionItem,
  isDiamond,
  isRing,
  isPendant,
  isGemstone,
  isWatch,
  isJewelry,
} from '../models/interfaces';
import AuctionProgressSidebar from '../components/AuctionProgressSidebar';
import CountdownTimer from '../components/CountdownTimer';
import './AuctionItemPage.css';
import { useWebSocket } from '../contexts/WebSocketContext'; // Import useWebSocket
import { da } from 'date-fns/locale';

type ObjectType = 'diamond' | 'ring' | 'gemstone' | 'pendant' | 'watch' | 'jewelry';

interface Labels {
  [key: string]: { [key: string]: string };
}

const labels: Labels = labels_en;

interface AuctionItemPageProps {
  onApplyFilters: (filters: any) => void;
  onChatContext: (chatContext: any) => void;
}

const AuctionItemPage: React.FC<AuctionItemPageProps> = ({ onApplyFilters, onChatContext }) => {
  const { id } = useParams<{ id: string }>();
  const [cookies] = useCookies(['armondai']); // Access cookies
  const [item, setItem] = useState<AuctionItem | null>(null);
  const [bidAmount, setBidAmount] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [product, setProduct] = useState<Product>({
    productType: 'Unknown',
    title: '',
    description: '',
    category: '',
  });

  const { socket, isConnected } = useWebSocket(); // Access socket and connection status

  const updateChatContext = (data: any) => {
    let paritalData = { ...data };
    if ('link' in paritalData) paritalData['link'] = undefined;
    if ('images' in paritalData) paritalData['images'] = undefined;
    if ('img' in paritalData) paritalData['img'] = undefined;
    if ('uploadedBy' in paritalData) paritalData['uploadedBy'] = undefined;

    onChatContext(paritalData);
  };
  useEffect(() => {
    console.log('AuctionItemPage useEffect: Socket:', socket, 'isConnected:', isConnected);
    // Initial fetch of auction item data
    const fetchAuctionItem = async () => {
      try {
        const response = await axios.get(`/api/auction-items/${id}`, {
          withCredentials: true,
        });
        setItem(response.data);
        updateChatContext(response.data);
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          alert('Session expired. Please log in again.');
          window.location.href = '/';
        }
      }
    };

    fetchAuctionItem();
  }, [id]);

  useEffect(() => {
    if (item) {
      setBidAmount(item.currentBid + 1);
      setProduct(item.product);
    }
  }, [item]);

  useEffect(() => {
    if (socket && isConnected) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('AuctionItemPage: Received priceUpdated event:', data);
        if (data.auctionId === id) {
          // Update the item state directly with the new price
          setItem((prevItem) => (prevItem ? { ...prevItem, currentBid: data.newPrice } : prevItem));
        }
      };

      console.log('AuctionItemPage: Socket is connected, setting up listeners');
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup on unmount
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket, isConnected, id]);

  if (!item) return <Typography>Loading...</Typography>;

  // Helper function to render a product's attribute dynamically
  const renderAttribute = (attribute: string, value: any, objectType: ObjectType) => {
    const label = labels[objectType][attribute] || attribute;
    if (
      value &&
      attribute !== 'description' &&
      attribute !== 'title' &&
      attribute !== 'type' &&
      attribute !== 'category'
    ) {
      return (
        <Typography variant="h5" key={attribute} className="attribute-item">
          <span className="attribute-name">{label}:</span>
          <span className="attribute-value">{value}</span>
        </Typography>
      );
    }
    return null;
  };

  // Object-type-based rendering
  const renderProductDetails = (objectType: ObjectType) => {
    return (
      <Card className="product-details-card">
        <CardContent>
          <Typography variant="h4" className="product-details-title">
            {product.productType || ''} Details
          </Typography>
          {Object.entries(product)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]) => renderAttribute(key, value, objectType))}
        </CardContent>
      </Card>
    );
  };

  const placeBid = () => {
    axios
      .post(
        `/api/auction-items/${id}/bid`,
        {
          amount: bidAmount,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setItem(response.data);
        setBidAmount(0);
      })
      .catch((error) => {
        console.error(error);
        alert('Failed to place bid. Please try again.');
      });
  };

  const setMaxBid = () => {
    axios
      .post(
        `/api/auction-items/${id}/max-bid`,
        {
          amount: bidAmount,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setItem(response.data);
        setBidAmount(0);
      })
      .catch((error) => {
        console.error(error);
        alert('Failed to set max bid. Please try again.');
      });
  };

  return (
    <Container className="auction-item-page">
      <Grid2 container spacing={3} sx={{ margin: 0 }} className="auction-content">
        {/* Top Section: Main Image and Auction Sidebar */}
        <Grid2
          container
          spacing={2}
          className="top-section"
          size={{ xs: 12 }}
          direction={{ sm: 'column', xs: 'column', md: 'row' }}
        >
          {/* Main Image and Thumbnails */}
          <Grid2 size={{ xs: 12, md: 8 }} className="main-image-container">
            <img
              src={item.images[0]}
              alt={product.title}
              className="main-image"
              onClick={() => setSelectedImage(item.images[0])}
            />
            {/* Thumbnails */}
            <div className="thumbnail-container">
              {item.images.slice(1).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.title} image ${index + 1}`}
                  className="thumbnail-image"
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
          </Grid2>
          {/* Auction Sidebar */}
          <Grid2 size={{ xs: 12, md: 4 }} className="auction-sidebar-area">
            <div className="count-down-area">
              <Typography variant="h5" className="current-bid">
                Closes in:
              </Typography>
              <CountdownTimer endDate={item.endDate} />
            </div>
            <AuctionProgressSidebar
              item={item}
              bidAmount={bidAmount}
              setBidAmount={setBidAmount}
              placeBid={placeBid}
              setMaxBid={setMaxBid}
              onApplyFilters={onApplyFilters}
            />
          </Grid2>
        </Grid2>
        {/* Product Details */}
        <Grid2 size={{ xs: 12 }} className="product-details-section">
          <Typography variant="h4" className="product-title">
            {product.title}
          </Typography>
          <Typography variant="h5" className="product-description">
            {product.description}
          </Typography>
          <Typography variant="h5" className="product-category">
            Category: {product.category}
          </Typography>
          <Typography variant="h5" className="product-end-date">
            Ends: {new Date(item.endDate).toLocaleString()}
          </Typography>
          {/* Dynamically render product attributes based on object type */}
          {product !== undefined && product.productType && isDiamond(product) && renderProductDetails('diamond')}
          {product.productType && isRing(product) && renderProductDetails('ring')}
          {product.productType && isPendant(product) && renderProductDetails('pendant')}
          {product.productType && isGemstone(product) && renderProductDetails('gemstone')}
          {product.productType && isWatch(product) && renderProductDetails('watch')}
          {product.productType && isJewelry(product) && renderProductDetails('jewelry')}
        </Grid2>
        {/* Close Grid2 */}
      </Grid2>
      {/* Popup for image zoom */}
      <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} maxWidth="xl" className="image-popup">
        {selectedImage && (
          <InnerImageZoom
            src={selectedImage}
            zoomSrc={selectedImage}
            zoomType="click"
            className="popup-image"
            imgAttributes={{ alt: product.title }}
            moveType="pan"
            zoomScale={3}
            hasSpacer={true}
          />
        )}
      </Dialog>
    </Container>
  );
};

export default AuctionItemPage;
