// src/components/settings/AddressInfo.tsx

import React from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import { UserSettings } from '../../models/UserSettings';
import './styles/AddressInfo.css';

interface AddressInfoProps {
  addressInfo: UserSettings['addressInfo'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalAddressInfo: UserSettings['addressInfo'];
}

const AddressInfo: React.FC<AddressInfoProps> = ({ addressInfo, setUserSettings, originalAddressInfo }) => {
  const handleBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      addressInfo: {
        ...prev.addressInfo,
        billingAddress: {
          ...prev.addressInfo.billingAddress,
          [name]: value,
        },
      },
    }));
  };

  const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      addressInfo: {
        ...prev.addressInfo,
        shippingAddress: {
          ...prev.addressInfo.shippingAddress,
          [name]: value,
        },
      },
    }));
  };

  const isBillingChanged = (field: keyof UserSettings['addressInfo']['billingAddress']) => {
    return addressInfo.billingAddress[field] !== originalAddressInfo.billingAddress[field];
  };

  const isShippingChanged = (field: keyof UserSettings['addressInfo']['shippingAddress']) => {
    return addressInfo.shippingAddress[field] !== originalAddressInfo.shippingAddress[field];
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Billing Address
      </Typography>
      <form className="form-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Street Address"
              name="street"
              fullWidth
              variant="outlined"
              value={addressInfo.billingAddress.street}
              onChange={handleBillingChange}
              className={isBillingChanged('street') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="City"
              name="city"
              fullWidth
              variant="outlined"
              value={addressInfo.billingAddress.city}
              onChange={handleBillingChange}
              className={isBillingChanged('city') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State/Province"
              name="state"
              fullWidth
              variant="outlined"
              value={addressInfo.billingAddress.state}
              onChange={handleBillingChange}
              className={isBillingChanged('state') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Zip/Postal Code"
              name="zip"
              fullWidth
              variant="outlined"
              value={addressInfo.billingAddress.zip}
              onChange={handleBillingChange}
              className={isBillingChanged('zip') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              name="country"
              fullWidth
              variant="outlined"
              value={addressInfo.billingAddress.country}
              onChange={handleBillingChange}
              className={isBillingChanged('country') ? 'changed-field' : ''}
            />
          </Grid>
        </Grid>
      </form>

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Shipping Address
      </Typography>
      <form className="form-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Street Address"
              name="street"
              fullWidth
              variant="outlined"
              value={addressInfo.shippingAddress.street}
              onChange={handleShippingChange}
              className={isShippingChanged('street') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="City"
              name="city"
              fullWidth
              variant="outlined"
              value={addressInfo.shippingAddress.city}
              onChange={handleShippingChange}
              className={isShippingChanged('city') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State/Province"
              name="state"
              fullWidth
              variant="outlined"
              value={addressInfo.shippingAddress.state}
              onChange={handleShippingChange}
              className={isShippingChanged('state') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Zip/Postal Code"
              name="zip"
              fullWidth
              variant="outlined"
              value={addressInfo.shippingAddress.zip}
              onChange={handleShippingChange}
              className={isShippingChanged('zip') ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              name="country"
              fullWidth
              variant="outlined"
              value={addressInfo.shippingAddress.country}
              onChange={handleShippingChange}
              className={isShippingChanged('country') ? 'changed-field' : ''}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddressInfo;
