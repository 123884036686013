import React from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { Typography } from '@mui/material';

// Countdown render function
const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a message when the countdown completes
    return (
      <Typography
        color="error"
        sx={{
          fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
        }}
      >
        Auction Ended
      </Typography>
    );
  } else {
    // Render the countdown
    return (
      <Typography
        sx={{
          fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
        }}
      >
        {days}d {hours}h {minutes}m {seconds}s
      </Typography>
    );
  }
};

// CountdownTimer component
interface CountdownTimerProps {
  endDate: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endDate }) => {
  return <Countdown date={new Date(endDate)} renderer={renderer} />;
};

// Export the module to avoid isolatedModules error
export default CountdownTimer;
