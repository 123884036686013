// src/components/settings/Preferences.tsx

import React from 'react';
import { TextField, Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { UserSettings } from '../../models/UserSettings';
import './styles/Preferences.css';

interface PreferencesProps {
  preferences: UserSettings['preferences'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalPreferences: UserSettings['preferences'];
}

const Preferences: React.FC<PreferencesProps> = ({ preferences, setUserSettings, originalPreferences }) => {
  const handleHobbiesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        hobbies: value,
      },
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        [name]: checked,
      },
    }));
  };

  const isHobbiesChanged = () => {
    return preferences.hobbies !== originalPreferences.hobbies;
  };

  const isPreferenceChanged = (field: keyof UserSettings['preferences']) => {
    return preferences[field] !== originalPreferences[field];
  };

  return (
    <form className="form-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Hobbies"
            fullWidth
            variant="outlined"
            placeholder="e.g., Reading, Hiking"
            value={preferences.hobbies}
            onChange={handleHobbiesChange}
            className={isHobbiesChanged() ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.receiveNewsletters}
                  onChange={handleCheckboxChange}
                  name="receiveNewsletters"
                  color="primary"
                />
              }
              label="Receive Newsletters"
              className={isPreferenceChanged('receiveNewsletters') ? 'changed-field-checkbox' : ''}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.receivePromotions}
                  onChange={handleCheckboxChange}
                  name="receivePromotions"
                  color="primary"
                />
              }
              label="Receive Promotions"
              className={isPreferenceChanged('receivePromotions') ? 'changed-field-checkbox' : ''}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.receiveUpdates}
                  onChange={handleCheckboxChange}
                  name="receiveUpdates"
                  color="primary"
                />
              }
              label="Receive Updates"
              className={isPreferenceChanged('receiveUpdates') ? 'changed-field-checkbox' : ''}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default Preferences;
