import React, { useState } from 'react';
import { Menu, MenuItem, Button, Divider } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './UserMenu.css';

interface UserMenuProps {
  user: {
    firstName: string;
    role: string;
  };
  onLogout: () => void;
  onApplyFilters: (filters: any) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ user, onLogout, onApplyFilters }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Helper function to render a MenuItem
  const renderMenuItem = (label: string, onClick: () => void, key: string) => (
    <MenuItem
      key={key}
      sx={{
        fontSize: '1.2rem',
        padding: { xs: 0.5, sm: 0.5, md: 1, lg: 1 },
        minHeight: '1rem',
      }}
      onClick={() => {
        onClick();
        handleMenuClose(); // Close menu after click
      }}
    >
      {label}
    </MenuItem>
  );

  // Define your menu items
  const menuItems = [
    {
      label: 'Settings Page',
      onClick: () => navigate('/settings'),
      key: 'settings',
    },
    {
      label: 'Select Language',
      onClick: () => navigate('/language'),
      key: 'language',
    },
    {
      label: 'Current Bids',
      onClick: () => {
        navigate('/');
        onApplyFilters({ myBids: true });
      },
      key: 'current-bids',
    },
    {
      label: 'Favourites',
      onClick: () => {
        navigate('/');
        onApplyFilters({ favorites: true });
      },
      key: 'favourites',
    },
  ];

  // Additional items for 'Supplier' or 'Admin'
  if (user.role === 'Supplier' || user.role === 'Admin') {
    menuItems.push({
      label: 'Auction Items',
      onClick: () => navigate('/auctions'),
      key: 'auction-items',
    });
  }

  // Additional items for 'Admin'
  if (user.role === 'Admin') {
    menuItems.push(
      {
        label: 'Suppliers',
        onClick: () => {
          navigate('/suppliers');
          onApplyFilters({});
        },
        key: 'suppliers',
      },
      {
        label: 'Users',
        onClick: () => {
          navigate('/users');
          onApplyFilters({});
        },
        key: 'users',
      }
    );
  }

  return (
    <div className="user-menu">
      <Button
        className="user-menu-button"
        onClick={handleMenuOpen}
        startIcon={<AccountCircle />}
        style={{ textTransform: 'none' }}
      >
        {user.firstName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        classes={{ paper: 'user-menu-paper' }}
        style={{ top: '24px', left: '15px' }}
      >
        {/* Loop through and render menu items */}
        {menuItems.map((item) => renderMenuItem(item.label, item.onClick, item.key))}
        <Divider />
        {renderMenuItem('Logout', onLogout, 'logout')}
      </Menu>
    </div>
  );
};

export default UserMenu;
