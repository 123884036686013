// src/components/settings/AccountDetails.tsx

import React from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import { UserSettings } from '../../models/UserSettings';
import './styles/AccountDetails.css';

interface AccountDetailsProps {
  accountDetails: UserSettings['accountDetails'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalAccountDetails: UserSettings['accountDetails'];
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ accountDetails, setUserSettings, originalAccountDetails }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      accountDetails: {
        ...prev.accountDetails,
        [name]: value,
      },
    }));
  };

  const isPasswordChanged = () => {
    return (
      accountDetails.currentPassword !== '' ||
      accountDetails.newPassword !== '' ||
      accountDetails.confirmNewPassword !== ''
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Account Details
      </Typography>
      <form className="form-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Date Joined" fullWidth variant="outlined" disabled value={accountDetails.dateJoined} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              fullWidth
              variant="outlined"
              value={accountDetails.currentPassword}
              onChange={handleChange}
              className={
                accountDetails.currentPassword !== originalAccountDetails.currentPassword ? 'changed-field' : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
              fullWidth
              variant="outlined"
              value={accountDetails.newPassword}
              onChange={handleChange}
              className={accountDetails.newPassword !== originalAccountDetails.newPassword ? 'changed-field' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm New Password"
              name="confirmNewPassword"
              type="password"
              fullWidth
              variant="outlined"
              value={accountDetails.confirmNewPassword}
              onChange={handleChange}
              className={
                accountDetails.confirmNewPassword !== originalAccountDetails.confirmNewPassword ? 'changed-field' : ''
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AccountDetails;
