// src/components/FilterSidebar.tsx

import React, { useState, useEffect, useRef } from 'react';
import { Drawer, FormControlLabel, Typography, Slider, Divider, Switch, TextField, Button, Chip } from '@mui/material';
import './FilterSidebar.css';
import { useNavigate } from 'react-router-dom';
import FilterSection from './FilterSection';
import DynamicFilterSection from './DynamicFilterSection';
import { categoryFieldsMap } from '../add-auction/fields/index';
import { set } from 'date-fns';
import { on } from 'events';

const categories = ['Watches', 'Jewelry', 'Diamonds', 'Gemstones'];

interface FilterSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCategory: string | null; // Accept selectedCategory as a prop
  filters: any;
  onApplyFilters: (filters: any) => void;
}

const MAX_PRICE = 3000;

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  selectedCategory,
  filters,
  onApplyFilters,
}) => {
  // Filters configuration
  const filtersConfig = [
    {
      key: 'categories',
      title: 'Categories',
      items: categories,
    },
  ];

  // State for selected items in filters
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({
    categories: [],
  });

  const [priceRange, setPriceRange] = useState<number[]>([0, MAX_PRICE]);
  const [myBids, setMyBids] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<boolean>(false);
  const [myAuctions, setMyAuctions] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchSupplier, setSearchSupplier] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');
  const [top, setTop] = useState<string>('64px');

  const [dynamicFilters, setDynamicFilters] = useState<Array<any>>([]);
  const [dynamicFilterValues, setDynamicFilterValues] = useState<{
    [key: string]: any;
  }>({});

  const isFilterChangedByUser = useRef(false);
  const navigate = useNavigate();

  // Update internal state when filters prop changes
  useEffect(() => {
    setSelectedFilters({
      categories: filters?.categories || [],
    });
    setPriceRange(filters?.priceRange || [0, MAX_PRICE]);
    setMyBids(filters?.myBids || false);
    setFavorites(filters?.favorites || false);
    setMyAuctions(filters?.myAuctions || false);
    setSearchQuery(filters?.q || '');
    setSearchSupplier(filters?.supplierName || '');
    setSupplier(filters?.supplier || '');
    setDynamicFilterValues(filters?.dynamicFilters || {});

    // Load dynamic filters based on categories
    if (filters?.categories && filters.categories.length === 1) {
      const category = filters.categories[0];
      const fields = categoryFieldsMap[category] || [];
      setDynamicFilters(fields);
    } else {
      setDynamicFilters([]);
    }
  }, [filters]);

  // Update selectedFilters when selectedCategory prop changes
  useEffect(() => {
    if (selectedCategory) {
      setSelectedFilters((prevState) => ({
        ...prevState,
        categories: [selectedCategory],
      }));

      const fields = categoryFieldsMap[selectedCategory] || [];
      setDynamicFilters(fields);
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        categories: [],
      }));
      setDynamicFilters([]);
    }
  }, [selectedCategory]);

  // Handle toggling of filter items
  const handleToggleFilterItem = (filterKey: string, item: string) => {
    isFilterChangedByUser.current = true;
    setSelectedFilters((prevState) => {
      const currentItems = prevState[filterKey] || [];
      let newItems;
      if (currentItems.includes(item)) {
        newItems = currentItems.filter((i) => i !== item);
      } else {
        newItems = [...currentItems, item];
      }

      // If the filterKey is 'categories', ensure only one category is selected
      if (filterKey === 'categories') {
        newItems = currentItems.includes(item) ? [] : [item];
      }

      return { ...prevState, [filterKey]: newItems };
    });

    // Update dynamic filters based on the new category selection
    if (filterKey === 'categories') {
      const newCategory = item;
      if (selectedFilters.categories.includes(item)) {
        // Deselecting the category
        setDynamicFilters([]);
        setDynamicFilterValues({});
      } else {
        const fields = categoryFieldsMap[newCategory] || [];
        setDynamicFilters(fields);
        setDynamicFilterValues({});
      }
    }
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    isFilterChangedByUser.current = true;
    setPriceRange(newValue as number[]);
  };

  const handleToggleSwitch = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    isFilterChangedByUser.current = true;
    setter((prev) => !prev);
  };

  const handlePriceRangeReset = () => {
    setPriceRange([0, MAX_PRICE]);
    isFilterChangedByUser.current = true;
  };

  // Handle search query change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isFilterChangedByUser.current = true;
    setSearchQuery(e.target.value);
  };

  const handleSearchSupplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isFilterChangedByUser.current = true;
    setSearchSupplier(e.target.value);
  };

  const handleClearAllFilters = () => {
    isFilterChangedByUser.current = true;
    setSelectedFilters({
      categories: [],
    });
    setPriceRange([0, MAX_PRICE]);
    setMyBids(false);
    setFavorites(false);
    setMyAuctions(false);
    setSearchQuery('');
    setSearchSupplier('');
    setSupplier('');
    setDynamicFilters([]);
    setDynamicFilterValues({});
    navigate('/');
  };

  // Handle dynamic filter delete
  const handleDynamicFilterDelete = (filterKey: string, value: any) => {
    isFilterChangedByUser.current = true;
    if (Array.isArray(dynamicFilterValues[filterKey])) {
      const newValues = dynamicFilterValues[filterKey].filter((v: string) => v !== value);
      setDynamicFilterValues((prev) => ({
        ...prev,
        [filterKey]: newValues,
      }));
    } else {
      setDynamicFilterValues((prev) => ({
        ...prev,
        [filterKey]: '',
      }));
    }
  };

  const handleDynamicFilterValues = (filterKey: string, value: any) => {
    isFilterChangedByUser.current = true;
    setDynamicFilterValues((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  // Apply filters when internal state changes, but only if changed by user
  useEffect(() => {
    if (isFilterChangedByUser.current) {
      const updatedFilters = {
        ...selectedFilters,
        priceRange,
        myBids,
        favorites,
        myAuctions,
        q: searchQuery,
        supplierName: searchSupplier,
        supplier,
        dynamicFilters: dynamicFilterValues, // Include dynamic filters
      };
      onApplyFilters(updatedFilters);
      isFilterChangedByUser.current = false;
    }
    // if all filters are cleared, reset the page to 1
    if (
      Object.values(selectedFilters).every((items) => items.length === 0) &&
      priceRange[0] === 0 &&
      priceRange[1] === MAX_PRICE &&
      !myBids &&
      !favorites &&
      !myAuctions &&
      searchQuery === '' &&
      searchSupplier === '' &&
      supplier === '' &&
      Object.keys(dynamicFilterValues).length === 0
    ) {
      setTop('64px');
    } else {
      setTop('115px');
    }
  }, [
    selectedFilters,
    priceRange,
    myBids,
    favorites,
    myAuctions,
    searchQuery,
    searchSupplier,
    supplier,
    dynamicFilterValues,
    onApplyFilters,
  ]);

  // Prepare selected filters for the SelectedFilters component
  const selectedFiltersChips = Object.entries(selectedFilters).flatMap(([filterKey, items]) =>
    items.map((item) => ({
      label: item,
      onDelete: () => handleToggleFilterItem(filterKey, item),
    }))
  );

  const dynamicFiltersChips = Object.entries(dynamicFilterValues).flatMap(([filterKey, value]) => {
    const field = dynamicFilters.find((f) => f.name === filterKey);
    const label = field ? field.label : filterKey;

    if (Array.isArray(value)) {
      return value.map((item) => ({
        label: `${label}: ${item}`,
        onDelete: () => handleDynamicFilterDelete(filterKey, item),
      }));
    } else {
      return value
        ? [
            {
              label: `${label}: ${value}`,
              onDelete: () => handleDynamicFilterDelete(filterKey, value),
            },
          ]
        : [];
    }
  });

  const otherFiltersChips = [
    ...(priceRange[0] !== 0 || priceRange[1] !== MAX_PRICE
      ? [
          {
            label: `$${priceRange[0]} - $${priceRange[1]}`,
            onDelete: handlePriceRangeReset,
          },
        ]
      : []),
    ...(myBids
      ? [
          {
            label: 'My Bids',
            onDelete: () => {
              setMyBids(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(favorites
      ? [
          {
            label: 'Favorites',
            onDelete: () => {
              setFavorites(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(myAuctions
      ? [
          {
            label: 'My Auctions',
            onDelete: () => {
              setMyAuctions(false);
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(searchQuery
      ? [
          {
            label: `Search: ${searchQuery}`,
            onDelete: () => {
              setSearchQuery('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(searchSupplier
      ? [
          {
            label: `Supplier: ${searchSupplier}`,
            onDelete: () => {
              setSearchSupplier('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
    ...(supplier
      ? [
          {
            label: `Supplier: ${supplier}`,
            onDelete: () => {
              setSupplier('');
              isFilterChangedByUser.current = true;
            },
          },
        ]
      : []),
  ];

  const allSelectedFilters = [...selectedFiltersChips, ...dynamicFiltersChips, ...otherFiltersChips];

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        // Remove the dark backdrop but keep it to detect clicks
        BackdropProps: {
          style: { backgroundColor: 'transparent' },
        },
      }}
      PaperProps={{
        style: {
          top: top,
          height: `calc(100% - ${top})`,
          width: '300px', // Adjust width as needed
        },
      }}
    >
      <div className="custom-filter-sidebar">
        {' '}
        {/* Custom container for higher CSS specificity */}
        {/* Selected Filters */}
        {allSelectedFilters.length > 0 && (
          <div className="selected-filters-section">
            <Typography variant="subtitle1" className="filter-section-title">
              Selected Filters
            </Typography>
            <div className="selected-filters-list">
              {allSelectedFilters.map((filter, index) => (
                <Chip key={index} label={filter.label} onDelete={filter.onDelete} className="filter-chip" />
              ))}
              <Divider className="filter-divider" />
              <Button size="small" onClick={handleClearAllFilters} className="clear-all-button">
                Clear All
              </Button>
            </div>
            <Divider className="filter-divider" />
          </div>
        )}
        {/* Filters Title */}
        <Typography variant="h6" className="filter-title">
          Filters
        </Typography>
        {/* Switch Filters */}
        <div className="toggle-filters">
          <FormControlLabel
            control={<Switch checked={myBids} onChange={() => handleToggleSwitch(setMyBids)} color="primary" />}
            label="My Bids"
          />
          <FormControlLabel
            control={<Switch checked={favorites} onChange={() => handleToggleSwitch(setFavorites)} color="primary" />}
            label="Favorites"
          />
          <FormControlLabel
            control={<Switch checked={myAuctions} onChange={() => handleToggleSwitch(setMyAuctions)} color="primary" />}
            label="My Auctions"
          />
        </div>
        <Divider className="filter-divider" />
        {/* Search Fields */}
        <TextField
          label="Search Items"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
          className="filter-search-field"
        />
        <Divider className="filter-divider" />
        {/* Categories Filter Section */}
        {filtersConfig.map(({ key, title, items }) => (
          <React.Fragment key={key}>
            <FilterSection
              title={title}
              items={items}
              selectedItems={selectedFilters[key]}
              onToggle={(item) => handleToggleFilterItem(key, item)}
            />
            <Divider className="filter-divider" />
          </React.Fragment>
        ))}
        {/* Price Range */}
        <Typography variant="subtitle1" className="filter-section-title">
          Price Range
        </Typography>
        <Slider
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          min={0}
          max={MAX_PRICE}
          className="filter-slider"
        />
        <Typography className="filter-price-range">
          Price: ${priceRange[0]} - ${priceRange[1]}
        </Typography>
        <Divider className="filter-divider" />
        {/* Supplier Search */}
        <TextField
          label="Supplier"
          value={searchSupplier}
          onChange={handleSearchSupplierChange}
          fullWidth
          margin="normal"
          className="filter-search-field"
        />
        <Divider className="filter-divider" />
        {/* Dynamic Sub-Filters Section */}
        {selectedFilters.categories.length === 1 && dynamicFilters.length > 0 && (
          <>
            <Typography variant="h6" className="filter-title">
              {selectedFilters.categories[0]} Filters
            </Typography>
            <DynamicFilterSection
              fields={dynamicFilters}
              dynamicFilterValues={dynamicFilterValues}
              onDynamicFilterChange={handleDynamicFilterValues}
            />
          </>
        )}
        <Divider className="filter-divider" />
      </div>
    </Drawer>
  );
};

export default FilterSidebar;
