// src/contexts/WebSocketContext.tsx

// src/contexts/WebSocketContext.tsx

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useCookies } from 'react-cookie';

// Define the shape of the context without token
interface WebSocketContextProps {
  socket: Socket | null;
  isConnected: boolean;
}

const WebSocketContext = createContext<WebSocketContextProps>({
  socket: null,
  isConnected: false,
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cookies] = useCookies(['armondai']); // Assuming JWT is stored in 'armondai' cookie
  const socketRef = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:5000'
      : process.env.REACT_APP_API_BASE_URL_WSS;

  useEffect(() => {
    console.log('Attempting to connect to WebSocket server at', API_BASE_URL);

    if (!socketRef.current) {
      // Initialize Socket.IO client without sending the token via auth
      socketRef.current = io(API_BASE_URL, {
        withCredentials: true, // Send cookies with the request
        transports: ['websocket'], // Use WebSocket only
      });

      // Connection established
      socketRef.current.on('connect', () => {
        console.log('Connected to WebSocket server');
        setIsConnected(true);
      });

      // Handle connection errors
      socketRef.current.on('connect_error', (err) => {
        console.error('WebSocket connection error:', err.message);
      });

      // Handle disconnection
      socketRef.current.on('disconnect', (reason) => {
        console.log('Disconnected from WebSocket server:', reason);
        setIsConnected(false);
      });

      // Optional: Listen for other custom events
      // socketRef.current.on('anotherEvent', (data) => {
      //   console.log('Received anotherEvent:', data);
      // });

      // Cleanup on unmount
      return () => {
        if (socketRef.current) {
          console.log('Disconnecting from WebSocket server');
          socketRef.current.disconnect();
          socketRef.current = null;
          setIsConnected(false);
        }
      };
    }
  }, [cookies.armondai, API_BASE_URL]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.io.on('reconnect_attempt', () => {
        console.log('Attempting to reconnect to WebSocket server...');
      });
    }
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket: socketRef.current, isConnected: isConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

/**
import React, { createContext, useContext, useEffect, useRef } from 'react';
// import { io, Socket } from 'socket.io-client'; // Disable WebSocket import temporarily
import { useCookies } from 'react-cookie';

// Define the shape of the context without token
interface WebSocketContextProps {
  socket: null; // Set socket to null
}

const WebSocketContext = createContext<WebSocketContextProps>({ socket: null });

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const [cookies] = useCookies(['armondai']); // Comment out cookies if not needed
  // const socketRef = useRef<Socket | null>(null); // Comment out socketRef if not needed
  // const API_BASE_URL = window.location.origin === 'http://localhost:3000' ? 'http://localhost:5000' : process.env.REACT_APP_API_BASE_URL_WSS;

  useEffect(() => {
    console.log('WebSocket connection is disabled for debugging.');
    
    // No WebSocket initialization here for now

    return () => {
      console.log('WebSocket connection cleanup.');
      // Cleanup logic if needed, but WebSocket is disabled
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket: null }}>
      {children}
    </WebSocketContext.Provider>
  );
};
*/
