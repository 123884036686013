// src/components/add-auction/renderFields.tsx

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import Grid2 from '@mui/material/Grid2'; // Use Grid from MUI
import { CurrentUser } from '../../models/interfaces';
import { SelectChangeEvent } from '@mui/material/Select'; // Import SelectChangeEvent
import LockIcon from '@mui/icons-material/Lock'; // Import lock icon

// Define the structure of each field
export interface Dependency {
  name: string;
  value: any;
}

export interface FieldDefinition {
  name: string;
  label: string;
  type: string;
  values?: string[];
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  min?: number;
  role?: string;
  dependentOn?: Dependency; // Updated to an object
  withPrevious?: boolean;
  ai_instruction?: string;
  // Add any additional properties as needed
}

// Define the type for form values
export interface ValuesType {
  [key: string]: any;
}

// Define the type for the handleChange function
export type HandleChangeType = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;

// Define the type for the handleSelectChange function
export type HandleSelectChangeType = (event: SelectChangeEvent<string>) => void;

// Props for the renderFields function
interface RenderFieldsProps {
  fields: FieldDefinition[];
  handleChange: HandleChangeType;
  handleSelectChange: HandleSelectChangeType;
  values: ValuesType;
  user: CurrentUser;
}

const RenderFields: React.FC<RenderFieldsProps> = ({ fields, handleChange, handleSelectChange, values, user }) => {
  if (!fields || fields.length === 0) {
    return null;
  }

  // Filter fields based on user role
  let filteredFields = fields;
  if (user.role !== 'admin') {
    // If the user is not an admin, filter out admin-only fields
    filteredFields = filteredFields.filter((field) => field.role !== 'admin');
  }

  const rows: FieldDefinition[][] = [];
  let currentRow: FieldDefinition[] = [];

  filteredFields.forEach((field) => {
    // Handle dependency
    if (field.dependentOn && values[field.dependentOn.name] !== field.dependentOn.value) {
      return; // Skip this field if dependency is not met
    }

    if (field.withPrevious && currentRow.length > 0) {
      currentRow.push(field);
    } else {
      if (currentRow.length > 0) {
        rows.push([...currentRow]);
        currentRow = [];
      }
      currentRow.push(field);
    }

    // Limit to two fields per row for better layout
    if (currentRow.length === 4) {
      rows.push([...currentRow]);
      currentRow = [];
    }
  });

  if (currentRow.length > 0) {
    rows.push([...currentRow]);
  }

  return (
    <>
      <Grid2>
        {rows.map((rowFields, rowIndex) => (
          <Grid2 container spacing={2} key={`row-${rowIndex}`} alignItems="flex-start" marginTop={2}>
            {rowFields.map((field) => {
              const xs = rowFields.length === 1 ? 12 : rowFields.length === 2 ? 6 : 4; // Adjusted for up to 3 fields

              // Determine if the field is read-only
              const isReadOnly = field.type === 'readonly';

              const fieldComponent =
                field.values && field.values.length > 0 ? (
                  // Render Select for fields with predefined values
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      label={field.label}
                      name={field.name}
                      value={values[field.name] || ''}
                      onChange={handleSelectChange} // Use handleSelectChange for Select fields
                      required={field.required || false}
                    >
                      {field.values.map((option: string) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  // Render TextField for other fields
                  <TextField
                    label={field.label}
                    name={field.name}
                    type={field.type === 'textarea' ? 'text' : field.type}
                    value={values[field.name] || ''}
                    onChange={handleChange} // Use handleChange for TextField
                    fullWidth
                    variant="outlined"
                    multiline={field.multiline || false}
                    rows={field.multiline ? field.rows || 6 : undefined}
                    InputLabelProps={field.type === 'date' || isReadOnly ? { shrink: true } : {}}
                    required={field.required || false}
                    inputProps={{
                      min: field.min,
                      readOnly: isReadOnly,
                    }}
                    InputProps={{
                      readOnly: isReadOnly,
                      startAdornment: isReadOnly ? (
                        <InputAdornment position="start">
                          <LockIcon color="disabled" />
                        </InputAdornment>
                      ) : undefined,
                      style: isReadOnly ? { backgroundColor: '#f5f5f5', cursor: 'not-allowed' } : {},
                    }}
                  />
                );

              return (
                <Grid2 size={{ xs }} key={field.name}>
                  {fieldComponent}
                </Grid2>
              );
            })}
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};

export default RenderFields;
