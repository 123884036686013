// src/components/AuctionProgressSidebar.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  CircularProgress,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import './AuctionProgressSidebar.css';
import { AuctionItem, Bid } from '../models/interfaces';
import axios from 'axios';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';

interface AuctionProgressSidebarProps {
  item: AuctionItem;
  bidAmount: number;
  setBidAmount: (amount: number) => void;
  placeBid: () => void;
  setMaxBid: () => void;
  onApplyFilters: (filters: any) => void;
}

const AuctionProgressSidebar: React.FC<AuctionProgressSidebarProps> = ({
  item,
  bidAmount,
  setBidAmount,
  placeBid,
  setMaxBid,
  onApplyFilters,
}) => {
  const [bidHistoryOpen, setBidHistoryOpen] = useState(false);
  const [bidHistory, setBidHistory] = useState<Bid[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { socket, isConnected } = useWebSocket(); // Access socket and connection status
  const navigate = useNavigate();

  // Fetch bid history from the server
  const fetchBidHistory = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`/api/auction-items/${item._id}/bid-history`, {
        params: {
          page: 1,
          limit: 10,
        },
        withCredentials: true,
      });
      setBidHistory(response.data.bids);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch bid history');
    } finally {
      setLoading(false);
    }
  };

  // Toggle bid history visibility and fetch data every time it's opened
  const handleBidHistoryToggle = () => {
    if (!bidHistoryOpen) {
      fetchBidHistory();
    }
    setBidHistoryOpen(!bidHistoryOpen);
  };

  const handleSupplierClick = () => {
    if (!item.supplier) {
      return;
    }
    console.log('Supplier clicked:', item.supplier.name);
    onApplyFilters({ supplier: item.supplier._id });
    navigate('/supplier/' + item.supplier._id);
  };

  useEffect(() => {
    console.log('AuctionProgressSidebar useEffect: Socket:', socket, 'isConnected:', isConnected);
    if (socket && isConnected && bidHistoryOpen) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('AuctionProgressSidebar: Received priceUpdated event:', data);
        if (data.auctionId === item._id) {
          fetchBidHistory();
        }
      };

      console.log('AuctionProgressSidebar: Socket is connected, setting up listeners');
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup on unmount or when dependencies change
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket, isConnected, item._id, bidHistoryOpen]);

  return (
    <Box className="auction-progress-sidebar">
      <Typography variant="h6">Current Bid</Typography>
      <Typography variant="h4" className="current-bid">
        ${item.currentBid}
      </Typography>
      <Typography variant="h6">Reserve price not met</Typography>

      {/* Expert estimate */}
      <Box className="expert-estimate">
        <img
          src="https://via.placeholder.com/50" // Placeholder for expert image
          alt="Expert"
          className="expert-image"
        />
        <Box>
          {item.expertEstimateMin !== undefined && item.expertEstimateMin > 0 && (
            <Typography variant="subtitle2">
              Expert Estimate: ${item.expertEstimateMin} - ${item.expertEstimateMax ?? 'N/A'}
            </Typography>
          )}
          <Typography variant="h6">Selected by Armond AI</Typography>
        </Box>
      </Box>

      {/* Quick bid buttons */}
      <Box className="quick-bid-buttons">
        {item.quickBids.map((amount) => (
          <Button key={amount} className="quick-bid-button" onClick={() => setBidAmount(amount)}>
            <Typography variant="h5">${amount}</Typography>
          </Button>
        ))}
      </Box>

      {/* Manual bid entry */}
      <TextField
        label="Enter Your Bid"
        type="text" // Keeping it text to validate user input more effectively
        fullWidth
        value={bidAmount !== 0 ? bidAmount : ''} // Prevent displaying 0 when input is cleared
        onChange={(e) => {
          const value = e.target.value;
          // Validate numeric input (allowing only digits and decimal point)
          if (/^\d*\.?\d*$/.test(value)) {
            setBidAmount(value === '' ? 0 : Number(value)); // Handle empty input
          }
        }}
        className="manual-bid-entry"
        style={{ marginBottom: '1rem', marginTop: '1rem' }}
        slotProps={{
          input: {
            style: { fontSize: '1.5rem', fontFamily: 'Arial' }, // Customize input font
          },
        }}
        InputLabelProps={{
          style: { fontSize: '1rem', fontFamily: 'Arial' }, // Customize label font
        }}
      />
      {/* Place bid and set max bid buttons */}
      <div className="bid-buttons">
        <Button variant="contained" fullWidth color="primary" onClick={placeBid} className="place-bid-button">
          Place Bid
        </Button>
        <Button variant="contained" fullWidth color="secondary" className="set-max-bid-button" onClick={setMaxBid}>
          Max Bid
        </Button>
      </div>

      {/* Auction closing details */}

      <Typography variant="h6" className="closing-details">
        Closes: {new Date(item.endDate).toLocaleString()}
      </Typography>
      <Typography variant="h6">Max Bid: {item.maxBid ? `$${item.maxBid}` : 'Not Set'}</Typography>
      <Typography variant="h6">Buyer Protection fee: 9% + $3</Typography>
      <Typography variant="h6">Shipping: $35 from {item.supplier ? item.supplier.country : 'N/A'}</Typography>
      <Typography variant="h6">
        Supplier:{' '}
        {item.supplier ? (
          <span
            onClick={() => handleSupplierClick()}
            style={{ cursor: 'pointer', color: 'blue' }} // Change cursor to pointer to indicate a link
          >
            {item.supplier.name}
          </span>
        ) : (
          'N/A'
        )}
      </Typography>
      {item.buyItNowPrice && item.buyItNowPrice > 0 && (
        <Typography variant="h6">Buy It Now: ${item.buyItNowPrice}</Typography>
      )}

      {/* Bid History Button */}
      <Box mt={2}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleBidHistoryToggle}
          endIcon={bidHistoryOpen ? <ExpandLess /> : <ExpandMore />}
        >
          <Typography variant="h5">Bid History</Typography>
        </Button>
        <Collapse in={bidHistoryOpen} timeout="auto" unmountOnExit>
          <Box className="bid-history-list" mt={1}>
            {loading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography color="error" textAlign="center" variant="h6">
                {error}
              </Typography>
            ) : (
              <List>
                {bidHistory.length > 0 ? (
                  bidHistory.map((bid, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={`$${bid.amount} by ${bid.bidder}`}
                          secondary={new Date(bid.timestamp).toLocaleString()}
                          primaryTypographyProps={{ variant: 'h5' }} // Adjust variant as needed
                          secondaryTypographyProps={{ variant: 'body1' }} // Adjust variant as needed
                        />
                      </ListItem>
                      {index < bidHistory.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No bids yet." />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default AuctionProgressSidebar;
