// src/pages/LandingPage.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Box, useTheme, useMediaQuery } from '@mui/material';
import { AuctionItem as AuctionItemType } from '../models/interfaces';
import AuctionCard from '../components/AuctionCard';
import { useCookies } from 'react-cookie';
import './LandingPage.css';
import TopSlideshow from '../components/TopSlideshow';
import Categories from '../components/Categories';
import { useLocation, useNavigate } from 'react-router-dom';

interface HorizontalAuctionListProps {
  title: string;
  items: AuctionItemType[];
}

const HorizontalAuctionList: React.FC<HorizontalAuctionListProps> = ({ title, items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className="horizontal-auction-list">
      <Typography variant={isMobile ? 'h6' : 'h5'} className="list-title">
        {title}
      </Typography>
      <Box className="auction-list-wrapper">
        <Box className="auction-list-items">
          {items.map((item) => (
            <Box key={item._id} className="auction-card-container fixed-width">
              <AuctionCard item={item} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const LandingPage: React.FC = () => {
  const [cookies] = useCookies(['armondai']);
  const [lastViewedItems, setLastViewedItems] = useState<AuctionItemType[]>([]);
  const [favoriteItems, setFavoriteItems] = useState<AuctionItemType[]>([]);
  const [bidItems, setBidItems] = useState<AuctionItemType[]>([]);
  const [closingSoonItems, setClosingSoonItems] = useState<AuctionItemType[]>([]);
  const [promotedItems, setPromotedItems] = useState<AuctionItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchLastViewedItems = async () => {
    try {
      const response = await axios.get('/api/user/me/last-viewed', {
        withCredentials: true,
      });
      setLastViewedItems(response.data.auctions);
    } catch (error) {
      console.error('Error fetching last viewed items:', error);
    }
  };

  const fetchFavoriteItems = async () => {
    try {
      const response = await axios.get('/api/user/me/favorites', {
        withCredentials: true,
      });
      setFavoriteItems(response.data.auctions);
    } catch (error) {
      console.error('Error fetching favorite items:', error);
    }
  };

  const fetchBidItems = async () => {
    try {
      const response = await axios.get('/api/auction-items/list', {
        withCredentials: true,
        params: { myBids: true, page: 1, limit: 10 },
      });
      setBidItems(response.data.auctions);
    } catch (error) {
      console.error('Error fetching bid items:', error);
    }
  };

  const fetchClosingSoonItems = async () => {
    try {
      const response = await axios.get('/api/auction-items', {
        params: {
          sortBy: 'endDate',
          order: 'asc',
          limit: 10,
        },
        withCredentials: true,
      });
      setClosingSoonItems(response.data.auctions);
    } catch (error) {
      console.error('Error fetching closing soon items:', error);
    }
  };

  const fetchPromotedItems = async () => {
    try {
      const response = await axios.get('/api/auction-items', {
        params: {
          promoted: true,
          limit: 20,
        },
        withCredentials: true,
      });
      setPromotedItems(response.data.auctions);
    } catch (error) {
      console.error('Error fetching promoted items:', error);
    }
  };

  const handleCategorySelect = (category: string) => {
    // console.log('handleCategorySelect called with category:', category);
    // const updatedFilters = {
    //   ...filters,
    //   categories: [category],
    // };
    // console.log('Updated filters:', updatedFilters);

    // onApplyFilters({ filter: [category] });
    navigate(`/category/${category}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchLastViewedItems(),
          fetchFavoriteItems(),
          fetchBidItems(),
          fetchClosingSoonItems(),
          fetchPromotedItems(),
        ]);
      } catch (err) {
        setError('Failed to load data.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Container className="landing-page">
      <Container className="categories-container">
        <div className="categories">
          <Categories onCategorySelect={handleCategorySelect} />
        </div>
      </Container>

      {isLoading ? (
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      ) : (
        <>
          {closingSoonItems.length > 0 && <TopSlideshow items={closingSoonItems.slice(0, 5)} />}

          {promotedItems.length > 0 && <HorizontalAuctionList title="Promoted Items" items={promotedItems} />}
          {favoriteItems.length > 0 && <HorizontalAuctionList title="Your Favorites" items={favoriteItems} />}
          {lastViewedItems.length > 0 && <HorizontalAuctionList title="Recently Viewed" items={lastViewedItems} />}
          {bidItems.length > 0 && <HorizontalAuctionList title="Your Bids" items={bidItems} />}
          {closingSoonItems.length > 0 && <HorizontalAuctionList title="Closing Soon" items={closingSoonItems} />}
        </>
      )}
    </Container>
  );
};

export default LandingPage;
