// src/models/interfaces.ts

// User Interface
export interface CurrentUser {
  email: string;
  userId: string;
  firstName: string;
  role: string;
}

// Bid Interface
export interface Bid {
  bidder: string;
  amount: number;
  timestamp: string;
}

// Base Product Interface
export interface Product {
  productType: string;
  title: string;
  description: string;
  category: string;
}

// Diamond Interface
export interface Diamond extends Product {
  shape: string;
  caratWeight: number;
  colorGrade: string;
  clarityGrade: string;
  cutGrade: string;
  certificateNumber: string;
  measurements: string;
  fluorescence: string;
  polish: string;
  symmetry: string;
  depthPercentage: number;
  tablePercentage: number;
  // Add other diamond-specific fields if needed
  numberOfDiamonds?: number; // e.g., "1"
  naturalFancyColour?: string; // e.g., "Fancy intense Brown"
  fancyColourIntensity?: string; // e.g., "Fancy"
  laboratoryReport?: string; // e.g., "International Gemological Institute (IGI)"
  sealedByLaboratory?: string; // e.g., "Yes"
  laserEngraved?: string; // e.g., "Yes"
  girdle?: string; // e.g., "Slightly thick"
  culet?: string; // e.g., "None"
  diamondTypeMainStone?: string; // e.g., "Diamond"
  totalCaratWeight?: number; // e.g., "0.16"
  cuttingStyle?: string; // e.g., "Brilliant cut"
  labReportNumber?: string; // e.g., "1234567890"
  weight?: string; // e.g., "3.03 g"
}

// Ring Interface
export interface Ring extends Product {
  material: string;
  ringSize: number;
  gemstone: string;
  metalType: string;
  metalFineness: string; // e.g., "18 kt."
  mainStone: string; // e.g., "Diamond", "Emerald"
  shapeOfMainStone: string; // e.g., "Round", "Oval"
  totalCaratWeight: number; // e.g., "0.16"
  totalCaratWeightAllStones: number; // e.g., "0.16"
  totalCaratWeightMainStone: number; // e.g., "0.16"
  totalCaratWeighSurroundingStones: number; // e.g., "0.16"

  totalWeight: string; // e.g., "3.03 g"
  condition: string; // e.g., "New", "Excellent condition"
  gender: string; // e.g., "Women", "Men"
  brand: string; // e.g., "No", "Yes"
  era: string; // e.g., "After 2000"

  // Optional parameters for rings with diamonds
  cuttingStyle?: string; // e.g., "Brilliant"
  cuttingStyleOfMainStone?: string; // e.g., "Round"
  cuttingStyleOfSurroundingStones?: string; // e.g., "Princess"
  numberOfDiamonds?: number; // e.g., 3 (for rings with multiple diamonds)
  numberOfDiamondsMainStone?: number; // e.g., 1 (for rings with a single diamond)
  numberOfDiamondsSurroundingStones?: number; // e.g., 2 (for rings with multiple diamonds)
  countryOrigin?: string; // e.g., "Italy", "Singapore"
}

// Gemstone Interface
export interface Gemstone extends Product {
  gemstoneType: string; // e.g., "Opal", "Sapphire", "Emerald"
  caratWeight: number; // e.g., "3.19"
  treatment: string; // e.g., "No evidence of treatment"
  origin: string; // e.g., "Ethiopia"
  laboratoryReport: string; // e.g., "Antwerp Laboratory for Gemstone Testing (ALGT)"
  sealedByLaboratory: string; // e.g., "Yes" or "No"
  shape: string; // e.g., "Oval", "Pear"
  cuttingStyle: string; // e.g., "Cabochon", "Faceted cut"
  gemstoneColour: string; // e.g., "Blue", "Green", "Multicolour"
  transparency: string; // e.g., "Transparent", "Semi-transparent"
  clarityGrade: string; // e.g., "Eye clean", "VS"
  labReportNumber: string; // e.g., "12345678"
  numberOfStones?: number; // Optional for cases like multicolour sapphires
  totalCaratWeight?: number; // Optional for cases like multicolour sapphires
  opticalEffect?: string; // Optional for cases like asterism
  cutGrade?: string; // Optional for cases like excellent cut
}

// Pendant Interface
export interface Pendant extends Product {
  material: string; // e.g., "Yellow gold", "White gold"
  fineness: string; // e.g., "18 kt."
  mainStone?: string; // e.g., "Tourmaline"
  numberOfMainStones?: number; // e.g., "1"
  totalCaratWeightOfMainStone?: number; // e.g., "2.63"
  surroundingStones?: string; // e.g., "Diamond"
  numberOfSurroundingStones?: number; // e.g., "40"
  totalCaratWeightOfSurroundingStones?: number; // e.g., "0.20"
  cuttingStyleOfMainStone?: string; // e.g., "Cabochon cut"
  shapeOfSurroundingStones?: string; // e.g., "Tapered"
  laboratoryReport?: string; // e.g., "No laboratory report"
  era: string; // e.g., "After 2000"
  totalWeight: string; // e.g., "2.48 g"
  condition: string; // e.g., "Excellent condition"
  gender: string; // e.g., "Women"
  countryOfOrigin: string; // e.g., "Italy", "Singapore"
  length?: string; // e.g., "3.1 cm"
  width?: string; // e.g., "2 cm"
}

// Watch Interface
export interface Watch extends Product {
  brand: string; // e.g., "TAG Heuer", "Seiko", "Geovani"
  wmodel: string; // e.g., "Professional 200m", "Diver", "Swiss Diamond Watch"
  referenceNumber: string; // e.g., "999.208A", "SKX009 - 7s26-0020"
  gender: string; // e.g., "Men", "Women", "Unisex"
  period: string; // e.g., "1990-1999", "2000-2010"
  movement: string; // e.g., "Quartz", "Mechanical automatic"
  caseMaterial: string; // e.g., "Steel", "Gold-plated", "Stainless steel"
  caseDiameter: string; // e.g., "28 mm", "42 mm", "37 mm"
  condition: string; // e.g., "Worn with little to no signs of use", "Unworn"
  lengthOfWatchBand: string; // e.g., "18 cm", "21 cm"
  extras?: string; // Optional: e.g., "Box", "Warranty"
  shippedInsured: string; // e.g., "Yes", "No"
  era: string; // e.g., "1900-2000", "After 2000"
}

export interface Jewelry extends Product {
  era: string;
  material?: string;
  totalWeight?: string;
  condition?: string;
  titleAdditionalInformation?: string;
  length?: string;
  diameter?: string;
  width?: string;
  ringSize?: string;
  height?: string;
  circumference?: string;
  braceletSize?: string;
}

// Union Type for All Product Types
export type ProductTypes = Diamond | Ring | Pendant | Gemstone | Watch | Jewelry;

export type AuctionSupplierInfo = {
  _id: string;
  name: string;
  country: string;
};

// Auction Item Interface
export interface AuctionItem {
  _id: string;
  startingPrice: number;
  reservePrice: number;
  buyItNowPrice: number;
  bids: Bid[];
  category: string;
  currentBid: number;
  startDate: string;
  endDate: string;
  status: string;
  product: ProductTypes;
  img: string;
  images: string[];
  quickBids: number[];
  maxBid: number;
  expertEstimateMin: number;
  expertEstimateMax: number;
  isFavorite?: boolean; // Add this optional property
  supplier?: AuctionSupplierInfo;
}
export interface GetAuctionsResponse {
  auctions: AuctionItem[];
  totalAuctions: number;
  currentPage: number;
  totalPages: number;
}
// Type Guard Functions
export function isDiamond(product: Product): product is Diamond {
  return product.productType === 'Diamond';
}

export function isRing(product: Product): product is Ring {
  return product.productType === 'Ring';
}

export function isPendant(product: Product): product is Pendant {
  return product.productType === 'Pendant';
}

export function isGemstone(product: Product): product is Gemstone {
  return product.productType === 'Gemstone';
}

export function isWatch(product: Product): product is Watch {
  return product.productType === 'Watch';
}

export function isJewelry(product: Product): product is Jewelry {
  return product.productType === 'Jewelry';
}
