// src/components/settings/PersonalInfo.tsx

import React from 'react';
import { TextField, Grid } from '@mui/material';
import { UserSettings } from '../../models/UserSettings';
import './styles/PersonalInfo.css';

interface PersonalInfoProps {
  personalInfo: UserSettings['personalInfo'];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
  originalPersonalInfo: UserSettings['personalInfo'];
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ personalInfo, setUserSettings, originalPersonalInfo }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSettings((prev) => ({
      ...prev,
      personalInfo: {
        ...prev.personalInfo,
        [name]: value,
      },
    }));
  };

  const isChanged = (field: keyof UserSettings['personalInfo']) => {
    return personalInfo[field] !== originalPersonalInfo[field];
  };

  return (
    <form className="form-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="First Name"
            name="firstName"
            fullWidth
            variant="outlined"
            required
            value={personalInfo.firstName}
            onChange={handleChange}
            className={isChanged('firstName') ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Last Name"
            name="lastName"
            fullWidth
            variant="outlined"
            required
            value={personalInfo.lastName}
            onChange={handleChange}
            className={isChanged('lastName') ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nickname"
            name="nickname"
            fullWidth
            variant="outlined"
            value={personalInfo.nickname}
            onChange={handleChange}
            className={isChanged('nickname') ? 'changed-field' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Date Joined" fullWidth variant="outlined" disabled value={personalInfo.dateJoined} />
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonalInfo;
