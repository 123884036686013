// src/components/settings/UserProfileSettingsPage.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Paper, Button } from '@mui/material';
import ProfileTabs from './ProfileTabs';
import { UserSettings } from '../../models/UserSettings';
import { useCookies } from 'react-cookie'; // Import useCookies
import './styles/UserProfileSettingsPage.css';

const UserProfileSettingsPage: React.FC = () => {
  const [cookies] = useCookies(['armondai']); // Access cookies
  const [originalSettings, setOriginalSettings] = useState<UserSettings | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await axios.get('/api/user/profile', {
          headers: {
            Authorization: `Bearer ${cookies.armondai}`, // Use token from cookies
          },
        });

        const userData = response.data;

        const settings: UserSettings = {
          personalInfo: {
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            nickname: userData.nickname || '',
            dateJoined: userData.dateJoined || '',
          },
          contactInfo: {
            email: userData.email || '',
            phoneNumber: userData.phoneNumber || '',
          },
          addressInfo: {
            billingAddress: userData.billingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: userData.shippingAddress || {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: userData.hobbies || '',
            receiveNewsletters: userData.receiveNewsletters || false,
            receivePromotions: userData.receivePromotions || false,
            receiveUpdates: userData.receiveUpdates || false,
          },
          accountDetails: {
            dateJoined: userData.dateJoined || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: userData.role || 'LoginUser', // Ensure role is included
        };

        setOriginalSettings(settings);
        setUserSettings(settings);
      } catch (error) {
        console.error('Error fetching user settings:', error);
        const blankSettings: UserSettings = {
          personalInfo: {
            firstName: '',
            lastName: '',
            nickname: '',
            dateJoined: '',
          },
          contactInfo: {
            email: '',
            phoneNumber: '',
          },
          addressInfo: {
            billingAddress: {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
            shippingAddress: {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: '',
            },
          },
          preferences: {
            hobbies: '',
            receiveNewsletters: false,
            receivePromotions: false,
            receiveUpdates: false,
          },
          accountDetails: {
            dateJoined: '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          role: 'LoginUser',
        };
        setOriginalSettings(blankSettings);
        setUserSettings(blankSettings);
      }
    };

    fetchUserSettings();
  }, [cookies.armondai]);

  const handleSave = async () => {
    if (!userSettings) return;
    setIsSaving(true);
    try {
      const settingsToUpdate = {
        personalInfo: userSettings.personalInfo,
        contactInfo: userSettings.contactInfo,
        addressInfo: userSettings.addressInfo,
        preferences: userSettings.preferences,
      };

      await axios.put('/api/user/settings', settingsToUpdate, {
        headers: {
          Authorization: `Bearer ${cookies.armondai}`, // Use token from cookies
        },
      });
      setOriginalSettings(userSettings);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving user settings:', error);
      alert('Failed to save settings.');
    } finally {
      setIsSaving(false);
    }
  };

  if (!userSettings) {
    return (
      <Container className="loading-container">
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        User Profile Settings
      </Typography>
      <Paper elevation={3} className="paper-container">
        <ProfileTabs
          userSettings={userSettings}
          setUserSettings={setUserSettings as React.Dispatch<React.SetStateAction<UserSettings>>}
          originalSettings={originalSettings!}
        />
        <div className="save-button-container">
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save All Changes'}
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default UserProfileSettingsPage;
