// src/components/chat/ChatButton.tsx

import React, { useEffect, useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { Fab, Tooltip } from '@mui/material';
import ChatInterface from './ChatInterface';
import './ChatButton.css';
import axios from 'axios';
import { inflateRaw } from 'zlib';
import { CurrentUser } from '../../models/interfaces';
import { ConfirmationProvider } from '../../contexts/ConfirmationContext';

interface ChatButtonProps {
  // Define props here
  getChatContext: () => any;
  onOpenFilters: () => void;
  user: CurrentUser | null;
  onApplyFilters: (filters: any) => void;
  filters: any;
}

const ChatButton: React.FC<ChatButtonProps> = ({ getChatContext, onOpenFilters, user, onApplyFilters, filters }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [sessionNumber, setSessionNumber] = useState<number>(0); // Initialize appropriately
  const [lastInteraction, setLastInteraction] = useState<any>(null); // Define the type based on your data
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadLastInteraction = async () => {
    try {
      const response = await axios.get('/api/armond/get_last_interaction', {
        withCredentials: true,
        params: { sessionNumber },
      });
      setLastInteraction(response.data.interaction);
      setLoading(false);
    } catch (error: any) {
      console.error('Error loading last interaction', error);
      setError('Failed to load last interaction.');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLastInteraction();
  }, []);

  const handleToggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <div className="chat-button-container">
      <Tooltip title={isChatOpen ? 'Close Chat' : 'Open Chat'} placement="left">
        <Fab
          className={`chat-button ${isChatOpen ? 'open' : ''}`}
          color={isChatOpen ? 'secondary' : 'primary'}
          aria-label={isChatOpen ? 'Close chat' : 'Open chat'}
          onClick={handleToggleChat}
        >
          {isChatOpen ? <CloseIcon /> : <ChatIcon />}
        </Fab>
      </Tooltip>

      {isChatOpen && (
        <ConfirmationProvider>
          <ChatInterface
            sessionNumber={0} // Pass appropriate session number
            lastInteraction={lastInteraction} // Pass appropriate last interaction
            getChatContext={getChatContext}
            onOpenFilters={onOpenFilters}
            user={user}
            onApplyFilters={onApplyFilters} // Pass the function to apply filters
            filters={filters} // Pass the filters here
          />
        </ConfirmationProvider>
      )}
    </div>
  );
};

export default ChatButton;
