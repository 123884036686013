// /src/components/add-auction/AnalyzeModal.tsx

import React, { useState, useRef, useEffect, Suspense } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import animationData from './lottie/spin.json';

// Dynamically import the Lottie component
const Lottie = React.lazy(() => import('lottie-react'));

interface AnalyzeModalProps {
  open: boolean;
  onClose: () => void;
  imageFiles: File[];
  coverImage: File | null;
  onAnalyzeComplete: (data: any) => void;
}

const AnalyzeModal: React.FC<AnalyzeModalProps> = ({ open, onClose, imageFiles, coverImage, onAnalyzeComplete }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const analyzeAbortControllerRef = useRef<AbortController | null>(null);

  const handleAnalyze = async () => {
    setSubmitting(true);
    const controller = new AbortController();
    analyzeAbortControllerRef.current = controller;

    const formData = new FormData();

    imageFiles.forEach((file) => {
      formData.append('images', file);
    });

    if (coverImage) {
      formData.append('coverImage', coverImage);
    }
    console.log('call analyze formData:', formData);
    try {
      const response = await fetch('/api/auction-items/analyze', {
        method: 'POST',
        body: formData,
        credentials: 'include',
        signal: controller.signal,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Auction item analyzed successfully', data);

        onAnalyzeComplete(data);
        onClose();
      } else {
        const errorData = await response.json();
        console.error('Error analyzing auction item:', errorData);
        alert('Failed to analyze auction item.');
        onClose();
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Analyze request was aborted');
      } else {
        console.error('Error:', error);
        alert('An unexpected error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
      analyzeAbortControllerRef.current = null;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (open) {
      handleAnalyze();
    }
  }, [open]);

  return (
    <Modal open={open}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: 'translate(-50%, -50%)' }}
        bgcolor="background.paper"
        p={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img src="/images/analyze.jpg" alt="Analyzing" style={{ width: '200px', height: '200px' }} />

          {/* Use Suspense to wrap the dynamically loaded Lottie component */}
          <Suspense fallback={<CircularProgress />}>
            <Lottie animationData={animationData} style={{ height: '200px', width: '200px' }} />
          </Suspense>
        </div>

        <CircularProgress style={{ marginTop: '50px' }} />

        <Typography variant="h6" gutterBottom style={{ marginTop: '30px' }}>
          Analyzing your auction item...
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (analyzeAbortControllerRef.current) {
              analyzeAbortControllerRef.current.abort();
            }
            setSubmitting(false);
            onClose();
          }}
          style={{ marginTop: '20px' }}
          disabled={!submitting}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default AnalyzeModal;
