// src/components/add-auction/AuctionDetailsForm.tsx

import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { SelectChangeEvent } from '@mui/material/Select';
import { productFields } from './fields/productFields';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { auctionItemFields } from './fields/auctionItemFields';
import { FieldDefinition, HandleSelectChangeType } from './renderFields';

// Import AdapterDayjs normally (not lazy)
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RenderFields from './renderFields';
import { CurrentUser } from '../../models/interfaces';
import { AuctionItem } from '../../models/interfaces';

// Lazy load DateTimePicker and LocalizationProvider
const DateTimePicker = React.lazy(() =>
  import('@mui/x-date-pickers/DateTimePicker').then((module) => ({
    default: module.DateTimePicker,
  }))
);
const LocalizationProvider = React.lazy(() =>
  import('@mui/x-date-pickers/LocalizationProvider').then((module) => ({
    default: module.LocalizationProvider,
  }))
);

// Import the RenderFields component

interface AuctionDetailsFormProps {
  auctionItem: AuctionItem;
  setAuctionItem: React.Dispatch<React.SetStateAction<AuctionItem>>;
  handleAuctionItemChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleProductChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleProductTypeSelection: (e: SelectChangeEvent<string>) => void;
  selectedProductType: string;
  getProductTypeFields: () => FieldDefinition[];
  renderFields: React.ComponentType<any>; // Updated to accept a component
  user: CurrentUser; // Added user prop
  handleSelectChange?: HandleSelectChangeType; // Ensure this is included
}

const AuctionDetailsForm: React.FC<AuctionDetailsFormProps> = ({
  auctionItem,
  setAuctionItem,
  handleAuctionItemChange,
  handleProductChange,
  handleProductTypeSelection,
  selectedProductType,
  getProductTypeFields,
  renderFields: RenderFieldsComponent, // Renamed for clarity
  user,
}) => {
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(auctionItem.startDate || new Date()));
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(auctionItem.endDate || new Date()));

  const myAuctionItemFields = auctionItemFields.filter(
    (field) => field.name !== 'startDate' && field.name !== 'endDate' && field.name !== 'category'
  );

  const handleSelectProductChange = (e: SelectChangeEvent<string>) => {
    const name = e.target.name;
    const value = e.target.value;

    setAuctionItem((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    if (startDate && endDate) {
      const strStartDate = dayjs(startDate).toISOString();
      const strEndDate = dayjs(endDate).toISOString();
      setAuctionItem((prev: any) => ({
        ...prev,
        startDate: strStartDate,
        endDate: strEndDate,
      }));
    }
  }, [startDate, endDate, setAuctionItem]);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Auction Details
      </Typography>
      <RenderFieldsComponent
        fields={myAuctionItemFields}
        handleChange={handleAuctionItemChange}
        handleSelectChange={handleAuctionItemChange} // Add this line
        values={auctionItem}
        user={user}
      />

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Preferred Auction Dates
      </Typography>

      <Grid2 container spacing={2}>
        <React.Suspense fallback={<CircularProgress />}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid2 size={{ sm: 12, md: 6 }}>
              <DateTimePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6 }}>
              <DateTimePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid2>
          </LocalizationProvider>
        </React.Suspense>
      </Grid2>

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Product Details
      </Typography>

      <RenderFieldsComponent
        fields={productFields}
        handleChange={handleProductChange}
        handleSelectChange={handleProductTypeSelection} // Add this line
        values={auctionItem.product}
        user={user}
      />

      <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
        <InputLabel id="product-type-label">Product Type</InputLabel>
        <Select
          labelId="product-type-label"
          label="Product Type"
          name="productType"
          value={selectedProductType || ''}
          onChange={(e) => {
            handleProductTypeSelection(e);
            handleSelectProductChange(e); // Separate handler for Select components
          }}
          required
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Diamond">Diamond</MenuItem>
          <MenuItem value="Gemstone">Gemstone</MenuItem>
          <MenuItem value="Watch">Watch</MenuItem>
          <MenuItem value="Jewelry">Jewelry</MenuItem>
        </Select>
      </FormControl>

      {selectedProductType && (
        <>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            {selectedProductType} Details
          </Typography>
          <RenderFieldsComponent
            fields={getProductTypeFields()}
            handleChange={handleProductChange}
            values={auctionItem.product}
            user={user}
            handleSelectChange={handleProductChange} // Correct handler
          />
        </>
      )}
    </>
  );
};

export default AuctionDetailsForm;
