// src/components/create-supplier/CreateSupplierPage.tsx

import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, TextField, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const CreateSupplierPage: React.FC = () => {
  const [supplierData, setSupplierData] = useState({
    name: '',
    description: '',
    website: '',
    contactEmail: '',
    contactPhone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    // Add users field
    users: [],
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // User search states
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      setLoading(true);
      const fetchSupplier = async () => {
        try {
          const response = await fetch(`/api/suppliers/${id}`, {
            method: 'GET',
            credentials: 'include',
          });
          if (response.ok) {
            const data = await response.json();
            setSupplierData(data);
            setSelectedUsers(data.users || []);
          } else {
            console.error('Failed to fetch supplier');
            setErrorMessage('Failed to fetch supplier data.');
          }
        } catch (error) {
          console.error('Error fetching supplier:', error);
          setErrorMessage('An error occurred while fetching supplier data.');
        } finally {
          setLoading(false);
        }
      };
      fetchSupplier();
    }
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setSupplierData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value,
        },
      }));
    } else {
      setSupplierData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUserSearch = async () => {
    if (!searchQuery) return;

    try {
      const response = await fetch(`/api/user/search?q=${encodeURIComponent(searchQuery)}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
      } else {
        console.error('Failed to search users');
      }
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleAddUser = (user: any) => {
    if (!selectedUsers.find((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleRemoveUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((user) => user._id !== userId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const method = isEditMode ? 'PUT' : 'POST';
    const url = isEditMode ? `/api/suppliers/${id}` : '/api/suppliers';

    const dataToSubmit = {
      ...supplierData,
      users: selectedUsers.map((user) => user._id),
    };

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        navigate('/suppliers');
      } else {
        const errorData = await response.json();
        console.error(`Failed to ${isEditMode ? 'update' : 'create'} supplier:`, errorData);
      }
    } catch (error) {
      console.error(`Error ${isEditMode ? 'updating' : 'creating'} supplier:`, error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <Container maxWidth="md" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        {isEditMode ? 'Edit Supplier' : 'Create Supplier'}
      </Typography>
      <Paper elevation={3} className="paper-container">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Supplier Name */}
            <Grid item xs={12}>
              <TextField
                label="Supplier Name"
                name="name"
                value={supplierData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={supplierData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            {/* Website */}
            <Grid item xs={12}>
              <TextField
                label="Website"
                name="website"
                value={supplierData.website}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            {/* Contact Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Email"
                name="contactEmail"
                value={supplierData.contactEmail}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            {/* Contact Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Phone"
                name="contactPhone"
                value={supplierData.contactPhone}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            {/* Address Fields */}
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Street"
                name="address.street"
                value={supplierData.address.street}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="address.city"
                value={supplierData.address.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="address.state"
                value={supplierData.address.state}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="ZIP Code"
                name="address.zip"
                value={supplierData.address.zip}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                name="address.country"
                value={supplierData.address.country}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* Assign Users */}
            <Grid item xs={12}>
              <Typography variant="h6">Assign Users</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Search Users"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" color="primary" onClick={handleUserSearch}>
                Search
              </Button>
            </Grid>

            {/* Display Search Results */}
            {searchResults.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Search Results:</Typography>
                <ul>
                  {searchResults.map((user) => (
                    <li key={user._id}>
                      {user.email} - {user.firstName} {user.lastName}
                      <Button variant="outlined" size="small" onClick={() => handleAddUser(user)}>
                        Add
                      </Button>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}

            {/* Display Selected Users */}
            {selectedUsers.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Selected Users:</Typography>
                <ul>
                  {selectedUsers.map((user) => (
                    <li key={user._id}>
                      {user.email} - {user.firstName} {user.lastName}
                      <Button variant="outlined" size="small" onClick={() => handleRemoveUser(user._id)}>
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>

          {/* Submit Button */}
          <div className="save-button-container">
            <Button variant="contained" color="primary" type="submit">
              {isEditMode ? 'Update Supplier' : 'Create Supplier'}
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateSupplierPage;
