// src/components/add-auction/AddAuctionPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Button, CircularProgress, Box, SelectChangeEvent } from '@mui/material';
import Grid2 from '@mui/material/Grid2'; // Ensure you're using the correct Grid2 import
import { useCookies } from 'react-cookie';
import imageCompression from 'browser-image-compression';

import { AuctionItem, CurrentUser, ProductTypes } from '../../models/interfaces';

import ImageUploadSection from './ImageUploadSection';
import AuctionDetailsForm from './AuctionDetailsForm';

// Import field definitions
import { diamondFields } from './fields/diamondFields';
import { ringFields } from './fields/ringFields';
import { pendantFields } from './fields/pendantFields';
import { gemstoneFields } from './fields/gemstoneFields';
import { watchFields } from './fields/watchFields';
import { jewelryFields } from './fields/jewelryFields';
import { productFields } from './fields/productFields'; // Assuming you have productFields
import AnalyzeModal from './AnalyzeModal'; // Import the new component
import { calculateTitleForJewelry } from './fields/jewelryFields';
import { calculateTitleForDiamond } from './fields/diamondFields';
import { calculateTitleForGemstone } from './fields/gemstoneFields';
import { calculateTitleForWatch } from './fields/watchFields';

// Import the new RenderFields component (if needed elsewhere)
import RenderFields, { FieldDefinition } from './renderFields';

import './styles/AddAuctionPage.css';
import { Watch } from '@mui/icons-material';

const MAX_IMAGE_COUNT = 10; // Maximum number of images allowed

interface AddAuctionPageProps {
  user: CurrentUser;
}

const AddAuctionPage: React.FC<AddAuctionPageProps> = ({ user }) => {
  const { id } = useParams<{ id: string }>(); // Get the auction ID from URL params
  const [isEditMode, setIsEditMode] = useState(Boolean(id));
  const navigate = useNavigate();
  const [cookies] = useCookies(['armondai']);
  const [analyzeModalOpen, setAnalyzeModalOpen] = useState<boolean>(false);

  const [auctionItem, setAuctionItem] = useState<AuctionItem>({
    _id: '',
    bids: [],
    currentBid: 0,
    status: '',
    startingPrice: 1,
    reservePrice: 0,
    buyItNowPrice: 0,
    category: '',
    startDate: '',
    endDate: '',
    expertEstimateMin: 100,
    expertEstimateMax: 200,
    product: {
      productType: '',
      title: '',
      description: '',
      category: '',
      // ... add other product-specific fields as needed
    } as ProductTypes,
    img: '',
    images: [],
    quickBids: [],
    maxBid: 0,
    isFavorite: false,
    supplier: {} as any,
  });

  const [selectedProductType, setSelectedProductType] = useState<string>('');
  const [imageFiles, setImageFiles] = useState<File[]>([]); // New images to upload
  const [existingImages, setExistingImages] = useState<string[]>([]); // URLs of existing images
  const [coverImage, setCoverImage] = useState<File | null>(null); // New cover image
  const [existingCoverImage, setExistingCoverImage] = useState<string>(''); // URL of existing cover image

  const [loading, setLoading] = useState<boolean>(false); // Loading state for fetching data
  const [error, setError] = useState<string>(''); // Error state for fetching data
  const [submitting, setSubmitting] = useState<boolean>(false); // Submitting state

  // Mapping from productType to fields and calculateTitle function
  const productTypeFieldsMap: {
    [key: string]: {
      fields: FieldDefinition[];
      calculateTitle: (productFields: any) => string;
    };
  } = {
    Jewelry: {
      fields: jewelryFields,
      calculateTitle: calculateTitleForJewelry,
    },
    Diamond: {
      fields: diamondFields,
      calculateTitle: calculateTitleForDiamond,
    },
    Gemstone: {
      fields: gemstoneFields,
      calculateTitle: calculateTitleForGemstone,
    },
    Watch: {
      fields: watchFields,
      calculateTitle: calculateTitleForWatch,
    },
    // ... other product types ...
  };

  // Handler to remove a newly added image
  const handleRemoveImage = (index: number) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
  };

  // Handler to remove an existing image
  const handleRemoveExistingImage = (index: number) => {
    setExistingImages((prev) => prev.filter((_, i) => i !== index));
  };

  // Handler for product type selection
  const handleProductTypeSelection = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string;
    setSelectedProductType(value);

    setAuctionItem((prev) => {
      const updatedProduct = {
        ...prev.product,
        productType: value,
      };

      // Get the calculateTitle function based on the new productType
      const calculateTitleFn = productTypeFieldsMap[value]?.calculateTitle;

      // Calculate the new title
      const calculatedTitle = calculateTitleFn ? calculateTitleFn(updatedProduct) : '';

      return {
        ...prev,
        product: {
          ...updatedProduct,
          title: calculatedTitle,
        },
      };
    });
  };

  // Handler for product fields change
  const handleProductChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string;
    const value = e.target.value;

    setAuctionItem((prev) => {
      const updatedProduct = {
        ...prev.product,
        [name]: value,
      };

      // Get the calculateTitle function based on productType
      const calculateTitleFn = productTypeFieldsMap[updatedProduct.productType]?.calculateTitle;

      // Calculate the new title
      const calculatedTitle = calculateTitleFn ? calculateTitleFn(updatedProduct) : '';

      return {
        ...prev,
        product: {
          ...updatedProduct,
          title: calculatedTitle,
        },
      };
    });
  };

  // Handler for auction item fields change
  const handleAuctionItemChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string;
    const value = e.target.value as string;

    setAuctionItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle image change
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      // Compress images
      const compressedFiles = await Promise.all(
        newFiles.map(async (file) => {
          try {
            console.log('compressing image:', file);
            const compressedFile = await imageCompression(file, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            });

            // Ensure the file retains the original extension
            const fileExtension = file.name.split('.').pop();
            const compressedFileWithExtension = new File(
              [compressedFile],
              `${compressedFile.name || file.name}.${fileExtension}`,
              { type: file.type }
            );

            return compressedFileWithExtension;
          } catch (error) {
            console.error('Error compressing image:', error);
            return file; // Fallback to original file if compression fails
          }
        })
      );

      // Check if the new selection would exceed the limit
      if (imageFiles.length + compressedFiles.length > MAX_IMAGE_COUNT) {
        alert(`You can only upload a maximum of ${MAX_IMAGE_COUNT} images.`);
        return;
      }

      // Update the state with the compressed files
      setImageFiles((prev) => [...prev, ...compressedFiles]);
    }
  };

  useEffect(() => {
    if (isEditMode && id) {
      setLoading(true);
      // Fetch the existing auction data
      fetch(`/api/auction-items/${id}`, {
        method: 'GET',
        credentials: 'include', // Include cookies for authentication
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Failed to fetch auction data');
        })
        .then((data: AuctionItem) => {
          setAuctionItem({
            ...data,
            // Keep startDate and endDate as strings
            startDate: data.startDate || '',
            endDate: data.endDate || '',
          });
          setSelectedProductType(data.product.productType || '');
          setExistingImages(data.images || []);
          setExistingCoverImage(data.img || '');
        })
        .catch((err) => {
          console.error(err);
          setError('Failed to load auction data.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isEditMode, id]);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();

    // Append auctionItem fields
    Object.keys(auctionItem).forEach((key) => {
      if (
        key !== 'product' &&
        key !== 'images' &&
        key !== 'img' &&
        key !== 'bids' &&
        key !== 'quickBids' &&
        key !== '_id'
      ) {
        formData.append(key, String((auctionItem as any)[key]));
        console.log(key, (auctionItem as any)[key]);
      }
    });

    // Append product fields
    Object.keys(auctionItem.product).forEach((key) => {
      formData.append(`product[${key}]`, String((auctionItem.product as any)[key]));
    });

    // Append existing images to keep
    existingImages.forEach((imageUrl) => {
      formData.append('existingImages', imageUrl);
    });

    // Append new images
    imageFiles.forEach((file) => {
      formData.append('images', file);
    });

    // Append existing cover image to keep
    if (existingCoverImage) {
      formData.append('existingCoverImage', existingCoverImage);
    }

    // Append new cover image
    if (coverImage) {
      formData.append('coverImage', coverImage);
    }

    try {
      const response = await fetch(isEditMode ? `/api/auction-items/${id}` : '/api/auction-items', {
        method: isEditMode ? 'PUT' : 'POST',
        body: formData,
        credentials: 'include', // Include credentials for authentication
      });

      if (response.ok) {
        // Handle successful response
        console.log(isEditMode ? 'Auction item updated successfully' : 'Auction item created successfully');
        navigate('/auctions'); // Redirect to auction list or detail page
      } else {
        // Handle error response
        const errorData = await response.json();
        console.error(isEditMode ? 'Error updating auction item:' : 'Error creating auction item:', errorData);
        alert(isEditMode ? 'Failed to update auction item.' : 'Failed to create auction item.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // Function to get fields based on selected product type
  const getProductTypeFields = (): FieldDefinition[] => {
    return productTypeFieldsMap[selectedProductType]?.fields || [];
  };

  // Function to get product fields (static fields)
  const getProductFields = (): FieldDefinition[] => {
    return productFields;
  };

  // Function to handle analyze completion
  const handleAnalyzeComplete = (data: any) => {
    if ('id' in data) {
      data.id = undefined;
    }
    if ('_id' in data) {
      data._id = undefined;
    }

    // Set the auction item state with the data from the server
    setAuctionItem((prev) => ({
      ...prev,
      ...data,
      product: {
        ...prev.product,
        ...data.product,
      },
    }));

    setSelectedProductType(data.product.productType || '');
    setExistingImages(data.images || []);
    setExistingCoverImage(data.img || '');
  };

  const handleAnalyze = () => {
    setAnalyzeModalOpen(true);
  };

  if (loading) {
    // Show a loading spinner while fetching data
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    // Show an error message if fetching data failed
    return (
      <Container maxWidth="md" className="main-container">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        {isEditMode ? 'Edit Auction' : 'Add New Auction'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Paper elevation={3} className="paper-container">
          {/* Image Upload Section */}
          <ImageUploadSection
            coverImage={coverImage}
            existingCoverImage={existingCoverImage}
            setCoverImage={setCoverImage}
            imageFiles={imageFiles}
            existingImages={existingImages}
            handleImageChange={handleImageChange}
            handleRemoveImage={handleRemoveImage}
            handleRemoveExistingImage={handleRemoveExistingImage}
          />
          <AuctionDetailsForm
            auctionItem={auctionItem}
            setAuctionItem={setAuctionItem}
            handleAuctionItemChange={handleAuctionItemChange}
            handleProductChange={handleProductChange}
            handleProductTypeSelection={handleProductTypeSelection}
            selectedProductType={selectedProductType}
            getProductTypeFields={getProductTypeFields}
            renderFields={RenderFields}
            user={user} // Pass the user prop
          />
          {/* Render Product Fields
          <RenderFields
            fields={getProductFields()}
            values={auctionItem.product}
            handleChange={handleProductChange}            
            user={user}
            handleSelectChange={handleProductTypeSelection}
          />
          Render Dynamic Product Type Fields
          <RenderFields
            fields={getProductTypeFields()}
            handleChange={handleProductChange}
            values={auctionItem.product}
            user={user}
            handleSelectChange={handleProductTypeSelection}
          /> */}
        </Paper>
        <div className="save-button-container" style={{ marginTop: '20px' }}>
          <Button variant="contained" color="primary" type="submit" disabled={submitting}>
            {submitting ? (isEditMode ? 'Updating...' : 'Creating...') : isEditMode ? 'Update Item' : 'Create Item'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAnalyze}
            disabled={submitting || imageFiles.length === 0}
            style={{ marginLeft: '10px' }}
          >
            Analyze
          </Button>
        </div>

        {/* Include the AnalyzeModal component */}
        <AnalyzeModal
          open={analyzeModalOpen}
          onClose={() => setAnalyzeModalOpen(false)}
          imageFiles={imageFiles}
          coverImage={coverImage}
          onAnalyzeComplete={handleAnalyzeComplete}
        />
      </form>
    </Container>
  );
};

export default AddAuctionPage;
