// src/components/AuctionCard.tsx

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import axios from 'axios';
import { AuctionItem } from '../models/interfaces';
import './AuctionCard.css';
import CountdownTimer from './CountdownTimer';

interface AuctionCardProps {
  item: AuctionItem;
}

const AuctionCard: React.FC<AuctionCardProps> = ({ item }) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(item.isFavorite || false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentBid, setCurrentBid] = useState<number>(item.currentBid);

  // Update currentBid if item.currentBid changes
  useEffect(() => {
    setCurrentBid(item.currentBid);
  }, [item.currentBid]);

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite); // Optimistic UI update
    setIsLoading(true);

    const apiCall = isFavorite
      ? axios.delete(`/api/auction-items/${item._id}/favorite`, {
          withCredentials: true,
        })
      : axios.post(`/api/auction-items/${item._id}/favorite`, {}, { withCredentials: true });

    apiCall
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error updating favorite:', error);
        setIsFavorite(isFavorite); // Revert state if error occurs
        setIsLoading(false);
      });
  };

  return (
    <Card className="auction-card">
      <CardContent className="auction-card-content">
        <div className="favorite-icon-container">
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <IconButton onClick={handleToggleFavorite}>
              {isFavorite ? <Favorite color="error" /> : <FavoriteBorder color="primary" />}
            </IconButton>
          )}
        </div>
        <Typography
          className="auction-card-title"
          sx={{
            fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
          }}
        >
          {item.product.title}
        </Typography>
        {/* Wrap the img with Link to make it clickable */}
        <Link to={`/auction/${item._id}`}>
          <img src={item.img} alt={item.product.title} className="auction-card-image" />
        </Link>
        <div className="auction-card-bid-timer">
          <Typography
            className="auction-card-title"
            sx={{
              fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.3rem' },
            }}
          >
            Current Bid: <b>${currentBid}</b>
          </Typography>
          <CountdownTimer endDate={item.endDate} />
        </div>
      </CardContent>
    </Card>
  );
};

export default AuctionCard;
