// src/components/Header.tsx

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Grid2,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Tooltip,
  Fade,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {
  AccountCircle,
  Search as SearchIcon,
  Sort as SortIcon,
  FilterAltOff as FilterAltOffIcon,
  FilterAlt as FilterAltIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import UserMenu from './UserMenu';
import './Header.css';
import CategoryButton from './CategoryButton';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  onOpenFilter?: () => void;
  user: any;
  onLogout: () => void;
  onLoginSuccess: (response: any) => void;
  onApplyFilters: (filters: any) => void;
  openLoginModal: boolean;
  setOpenLoginModal: (open: boolean) => void;
  filters: any;
}

const MAX_PRICE = 3000;

const sortOptions = [
  { value: '', label: 'None' },
  { value: 'timeToClose', label: 'Time to Close' },
  { value: 'priceLowToHigh', label: 'Price Low to High' },
  { value: 'priceHighToLow', label: 'Price High to Low' },
  { value: 'numberOfBids', label: 'Number of Bids' },
];

const categories = [
  { name: 'Watches', path: '/category/watch', filter: 'categories' },
  { name: 'Jewelry', path: '/category/jewelry', filter: 'categories' },
  { name: 'Diamonds', path: '/category/diamond', filter: 'categories' },
  { name: 'Gemstones', path: '/category/gemstone', filter: 'categories' },
  { name: 'My Bids', path: '/category/myBids', filter: 'myBids' },
  { name: 'My Favourites', path: '/category/myFavourite', filter: 'favorites' },
  // Add more as needed
];

const Header: React.FC<HeaderProps> = ({
  onOpenFilter,
  user,
  onLogout,
  onLoginSuccess,
  onApplyFilters,
  openLoginModal,
  setOpenLoginModal,
  filters,
}) => {
  const location = useLocation(); // Get the current location
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false); // For hamburger menu
  const navigate = useNavigate();

  // Function to handle sorting
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelect = (sortValue: string) => {
    const updatedFilters = { ...filters, sort: sortValue };
    onApplyFilters(updatedFilters);
    handleSortMenuClose();
  };

  // Function to handle login success
  const handleLoginSuccessInternal = (response: any) => {
    onLoginSuccess(response);
    setOpenLoginModal(false);
  };

  const handleLoginFailure = () => {
    console.log('Login Failed');
  };

  const handleOpenModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseModal = () => {
    setOpenLoginModal(false);
  };

  // Search handling
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    const updatedFilters = { ...filters, q: searchQuery.trim() };
    onApplyFilters(updatedFilters);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  // Function to handle removing a specific filter
  const handleRemoveFilter = (filterType: string, value: any) => {
    const updatedFilters = { ...filters };
    if (
      filterType === 'categories' ||
      filterType === 'shapes' ||
      filterType === 'materials' ||
      filterType === 'gemstones'
    ) {
      updatedFilters[filterType] = updatedFilters[filterType].filter((item: string) => item !== value);
    } else if (filterType === 'priceRange') {
      updatedFilters.priceRange = [0, MAX_PRICE];
    } else if (filterType === 'myBids' || filterType === 'favorites' || filterType === 'myAuctions') {
      updatedFilters[filterType] = false;
    } else if (filterType === 'q') {
      updatedFilters.q = '';
    } else if (filterType === 'sort') {
      updatedFilters.sort = '';
    } else if (filterType === 'supplier') {
      updatedFilters.supplier = '';
    } else if (filterType === 'supplierName') {
      updatedFilters.supplierName = '';
    } else if (filterType === 'dynamicFilters') {
      delete updatedFilters.dynamicFilters[value];
    }
    onApplyFilters(updatedFilters);
  };

  // Function to clear all filters
  const handleClearAllFilters = () => {
    const clearedFilters = {
      categories: [],
      shapes: [],
      materials: [],
      gemstones: [],
      priceRange: [0, MAX_PRICE],
      myBids: false,
      favorites: false,
      myAuctions: false,
      q: '',
      sort: '',
      supplier: '',
      supplierName: '',
      dynamicFilters: {},
    };
    onApplyFilters(clearedFilters);
  };

  // Check if any filters are applied
  const hasFiltersApplied = () => {
    return (
      (filters.categories && filters.categories.length > 0) ||
      (filters.shapes && filters.shapes.length > 0) ||
      (filters.materials && filters.materials.length > 0) ||
      (filters.gemstones && filters.gemstones.length > 0) ||
      (filters.priceRange && (filters.priceRange[0] !== 0 || filters.priceRange[1] !== MAX_PRICE)) ||
      filters.myBids ||
      filters.favorites ||
      filters.myAuctions ||
      filters.q ||
      filters.sort ||
      filters.supplier ||
      filters.supplierName ||
      (filters.dynamicFilters && filters.dynamicFilters !== undefined && Object.keys(filters.dynamicFilters).length > 0)
    );
  };

  const handleGoHome = () => {
    // Clear all filters when going back to home
    onApplyFilters({});
    navigate('/');
  };
  // Function to toggle the mobile drawer
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  // Function to handle category selection with correct type
  const handleCategorySelectInternal = (category: string, filter_name: string) => {
    // Update the filters to include the selected category
    // Assuming single category selection; modify as needed for multiple
    console.log('Category Selected:', category, filter_name);
    const filter_value = filter_name === 'categories' ? [category] : 'true';
    const updatedFilters = { [filter_name]: filter_value };
    onApplyFilters(updatedFilters);
  };

  return (
    <AppBar position="fixed" className="header-appbar">
      <Toolbar className="header-toolbar">
        {/* Hamburger Menu Icon for Mobile */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          className="header-hamburger-menu"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <div className="header-left">
          <Grid2
            container
            spacing={{ sm: 'none', md: 2 }}
            rowSpacing={0}
            columnSpacing={{ sm: 'none', md: 3 }}
            className="header-grid"
          >
            <Grid2 size={{ sm: 1, md: 12 }}>
              <Button onClick={handleGoHome} className="header-home-button">
                ArmondAI
              </Button>
            </Grid2>
            <Grid2 size={1}>
              {onOpenFilter && (
                <Tooltip title="Filters">
                  <Button
                    variant="text"
                    onClick={onOpenFilter}
                    className="header-filter-button"
                    startIcon={<FilterAltIcon />}
                  ></Button>
                </Tooltip>
              )}
            </Grid2>
          </Grid2>
        </div>

        {/* Center with Search */}
        <Grid2 container justifyContent="center" className="header-grid">
          <Box display="flex" alignItems="center" className="header-search-box">
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              className="header-search-input"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: 4,
                  paddingRight: 0,
                },
              }}
            />
            <IconButton onClick={handleSearchSubmit} className="header-search-button" aria-label="search" sx={{ p: 1 }}>
              <SearchIcon className="header-search-icon" />
            </IconButton>
          </Box>
        </Grid2>

        {/* Right area with Login/Profile */}
        <div className="header-right">
          {user ? (
            <UserMenu user={user} onLogout={onLogout} onApplyFilters={onApplyFilters} />
          ) : (
            <Button className="header-login-button" startIcon={<AccountCircle />} onClick={handleOpenModal}>
              Login
            </Button>
          )}
        </div>
      </Toolbar>

      {/* Categories Navigation for Desktop */}
      <Box className="header-categories" sx={{ display: { xs: 'none', md: 'flex' } }}>
        {categories.map((category) => (
          <CategoryButton
            key={category.name}
            name={category.name}
            filter_name={category.filter}
            path={category.path}
            onCategorySelect={handleCategorySelectInternal} // Use the wrapper
          />
        ))}
      </Box>

      {/* Categories Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {categories.map((category) => (
              <ListItem key={category.name} disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={category.path}
                  onClick={() => handleCategorySelectInternal(category.name, category.filter)} // Apply filter on mobile
                >
                  <ListItemText primary={category.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Selected Filters Section */}
      {location.pathname === '/' && hasFiltersApplied() && (
        <div className="header-filters-section">
          <div className="header-selected-filters-list">
            {/* Display Chips for selected filters */}
            {filters.categories &&
              filters.categories.map((category: string) => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleRemoveFilter('categories', category)}
                  className="filter-chip"
                />
              ))}
            {filters.shapes &&
              filters.shapes.map((shape: string) => (
                <Chip
                  key={shape}
                  label={shape}
                  onDelete={() => handleRemoveFilter('shapes', shape)}
                  className="filter-chip"
                />
              ))}
            {filters.materials &&
              filters.materials.map((material: string) => (
                <Chip
                  key={material}
                  label={material}
                  onDelete={() => handleRemoveFilter('materials', material)}
                  className="filter-chip"
                />
              ))}
            {filters.gemstones &&
              filters.gemstones.map((gemstone: string) => (
                <Chip
                  key={gemstone}
                  label={gemstone}
                  onDelete={() => handleRemoveFilter('gemstones', gemstone)}
                  className="filter-chip"
                />
              ))}
            {filters.priceRange && (filters.priceRange[0] !== 0 || filters.priceRange[1] !== MAX_PRICE) && (
              <Chip
                label={`$${filters.priceRange[0]} - $${filters.priceRange[1]}`}
                onDelete={() => handleRemoveFilter('priceRange', null)}
                className="filter-chip"
              />
            )}
            {filters.myBids && (
              <Chip label="My Bids" onDelete={() => handleRemoveFilter('myBids', null)} className="filter-chip" />
            )}
            {filters.favorites && (
              <Chip label="Favorites" onDelete={() => handleRemoveFilter('favorites', null)} className="filter-chip" />
            )}
            {filters.myAuctions && (
              <Chip
                label="My Auctions"
                onDelete={() => handleRemoveFilter('myAuctions', null)}
                className="filter-chip"
              />
            )}
            {filters.q && (
              <Chip
                label={`Search: ${filters.q}`}
                onDelete={() => handleRemoveFilter('q', null)}
                className="filter-chip"
              />
            )}
            {filters.supplier && (
              <Chip
                label={`Supplier: ${filters.supplier}`}
                onDelete={() => handleRemoveFilter('supplier', null)}
                className="filter-chip"
              />
            )}
            {filters.supplierName && (
              <Chip
                label={`Supplier: ${filters.supplierName}`}
                onDelete={() => handleRemoveFilter('supplierName', null)}
                className="filter-chip"
              />
            )}
            {filters.dynamicFilters &&
              filters.dynamicFilters !== undefined &&
              Object.keys(filters.dynamicFilters).map((key) => (
                <Chip
                  key={key}
                  label={`${key}: ${filters.dynamicFilters[key]}`}
                  onDelete={() => handleRemoveFilter('dynamicFilters', key)}
                  className="filter-chip"
                />
              ))}
            {filters.sort && (
              <Chip
                label={`Sort: ${filters.sort}`}
                onDelete={() => handleRemoveFilter('sort', null)}
                className="filter-chip"
              />
            )}
          </div>
          {/* Replace "Clear All" Button with IconButton */}
          <Tooltip title="Clear All Filters">
            <IconButton
              aria-label="clear all filters"
              onClick={handleClearAllFilters}
              size="large"
              className="clear-all-button"
            >
              <FilterAltOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {/* Sort Options */}
          <Box display="flex" alignItems="center" className="sort-select">
            <Tooltip title="Sort By">
              <IconButton aria-label="sort by" onClick={handleSortMenuOpen} size="large" color="inherit">
                <SortIcon />
              </IconButton>
            </Tooltip>
            <Menu
              TransitionComponent={Fade}
              anchorEl={anchorEl}
              open={openSortMenu}
              onClose={handleSortMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
            >
              {sortOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={filters.sort === option.value}
                  onClick={() => handleSortSelect(option.value)}
                  sx={{
                    fontSize: {
                      xs: '1rem',
                      sm: '1rem',
                      md: '2rem',
                      lg: '2rem',
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      )}

      {/* Login Modal */}
      <Dialog open={openLoginModal} onClose={handleCloseModal}>
        <DialogTitle>Login with Google</DialogTitle>
        <DialogContent>
          <GoogleLogin
            onSuccess={handleLoginSuccessInternal} // Updated handler name
            onError={handleLoginFailure}
          />
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default Header;
