// src/components/CategoryButton.tsx

import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import './CategoryButton.css';

interface CategoryButtonProps {
  name: string;
  filter_name: string;
  path: string;
  onCategorySelect?: (category: string, filter_name: string) => void; // New Prop
}

const CategoryButton: React.FC<CategoryButtonProps> = ({ name, filter_name, path, onCategorySelect }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  const handleClick = () => {
    if (onCategorySelect) {
      onCategorySelect(name, filter_name);
    }
  };

  return (
    <div className="header-category-container">
      <Button
        component={RouterLink}
        to={path}
        className={`header-category ${isActive ? 'active' : ''}`}
        onClick={handleClick} // Trigger the handler on click
      >
        {name}
      </Button>
    </div>
  );
};

export default CategoryButton;
