// src/components/PrivateRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUser } from '../models/interfaces';

interface PrivateRouteProps {
  children: React.ReactElement;
  requiredRoles: string[];
  user: CurrentUser | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRoles, user }) => {
  if (!user) {
    // If user data is not yet loaded, you can render a loading spinner or null
    return null; // Or a loading spinner
  }

  if (!user.role || !requiredRoles.includes(user.role)) {
    // If the user is not authenticated or doesn't have the required role, redirect
    return <Navigate to="/" replace />;
  }

  // If the user is authenticated and has the required role, render the child component
  return children;
};

export default PrivateRoute;
