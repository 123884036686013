// src/components/auction/AuctionListPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Pagination,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import './styles/AuctionListPage.css'; // Import the new CSS file
import { CurrentUser } from '../../models/interfaces';
import { useWebSocket } from '../../contexts/WebSocketContext';

interface AuctionItem {
  _id: string;
  product: { title: string; description: string };
  currentBid: number;
  status: string;
  startDate: string;
  endDate: string;
}

interface AuctionListPageProps {
  loginUser: CurrentUser;
}

const AuctionListPage: React.FC<AuctionListPageProps> = ({ loginUser }) => {
  const [auctions, setAuctions] = useState<AuctionItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const limit = 50;
  const [onlyMyAuctions, setOnlyMyAuctions] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false); // New loading state

  // State for Delete Confirmation Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAuctionId, setSelectedAuctionId] = useState<string | null>(null);

  const fetchAuctions = async (pageNumber = 1, query = '') => {
    setIsLoading(true); // Start loading
    try {
      const response = await fetch(
        `/api/auction-items/list?q=${encodeURIComponent(query)}&page=${pageNumber}&limit=${limit}&myAuctions=${onlyMyAuctions}`,
        { method: 'GET', credentials: 'include' }
      );
      if (response.ok) {
        const data = await response.json();
        setAuctions(data.auctions);
        setTotalPages(data.totalPages);
      } else {
        setErrorMessage('Failed to fetch auctions');
      }
    } catch (error) {
      setErrorMessage('Error fetching auctions');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchAuctions(page, searchQuery);
  }, [page, searchQuery, onlyMyAuctions]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  const handleToggle = () => {
    setOnlyMyAuctions(!onlyMyAuctions);
    setPage(1);
  };

  // Handler to open the delete confirmation modal
  const handleDeleteClick = (auctionId: string) => {
    setSelectedAuctionId(auctionId);
    setOpenDeleteModal(true);
  };

  // Handler to confirm deletion
  const handleDeleteConfirm = async () => {
    if (!selectedAuctionId) return;

    try {
      const response = await fetch(`/api/auction-items/${selectedAuctionId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        setSuccessMessage('Auction item deleted successfully');
        // Remove the deleted auction from the state
        setAuctions((prevAuctions) => prevAuctions.filter((auction) => auction._id !== selectedAuctionId));
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to delete auction');
      }
    } catch (error) {
      setErrorMessage('Error deleting auction');
    } finally {
      setOpenDeleteModal(false);
      setSelectedAuctionId(null);
    }
  };

  // Handler to cancel deletion
  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setSelectedAuctionId(null);
  };

  return (
    <Container maxWidth="lg" className="main-container">
      <Typography variant="h4" component="h1" gutterBottom>
        Auction Management
      </Typography>

      <div>
        {/* Add Auction Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/add-auction')}
          className="add-auction-button"
        >
          Add Item
        </Button>
        {/* Add Toggle for everyone auction filter */}

        {loginUser.role === 'Admin' ? (
          <FormControlLabel
            control={<Switch checked={onlyMyAuctions} onChange={() => handleToggle()} color="primary" />}
            label="Only My Items"
            className="toggle-switch"
          />
        ) : null}
      </div>
      {/* Search TextField */}

      <TextField label="Search Auctions" value={searchQuery} onChange={handleSearchChange} fullWidth margin="normal" />

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Current Bid</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : auctions.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No items in the list.
                  </TableCell>
                </TableRow>
              ) : (
                auctions.map((auction) => (
                  <TableRow key={auction._id}>
                    <TableCell>{auction.product.title}</TableCell>
                    <TableCell>{auction.product.description}</TableCell>
                    <TableCell>{auction.currentBid}</TableCell>
                    <TableCell>{auction.status}</TableCell>
                    <TableCell>{new Date(auction.startDate).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(auction.endDate).toLocaleDateString()}</TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" onClick={() => navigate(`/edit-auction/${auction._id}`)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteClick(auction._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        {!isLoading && auctions.length > 0 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            className="pagination-container"
          />
        )}
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteModal} onClose={handleDeleteCancel} aria-labelledby="delete-confirmation-dialog">
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this auction item?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AuctionListPage;
