// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { LanguageProvider } from './components/chat/LanguageContext';
import ErrorBoundary from './components/ErrorBoundary'; // Import the ErrorBoundary

const container = document.getElementById('root');
const root = createRoot(container!); // Assumes 'root' is not null

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <LanguageProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LanguageProvider>
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Currently registering the service worker
serviceWorker.unregister();
