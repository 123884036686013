// src/App.tsx

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import Header from './components/Header';
import FilterSidebar from './components/filters/FilterSidebar';
import HomePage from './pages/HomePage';
import AuctionItemPage from './pages/AuctionItemPage';
import UserProfileSettingsPage from './components/settings/UserProfileSettingsPage';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import './App.css';
import AddAuctionPage from './components/add-auction/AddAuctionPage';
import CreateSupplierPage from './components/create-supplier/CreateSupplierPage';
import PrivateRoute from './components/PrivateRoute';
import SupplierListPage from './components/supplier/SupplierListPage';
import UserListPage from './components/users/UserListPage';
import EditUserPage from './components/users/EditUserPage';
import AuctionListPage from './components/auction/AuctionListPage';
import { CurrentUser } from './models/interfaces';
import { WebSocketProvider } from './contexts/WebSocketContext'; // Import the provider
import CircularProgress from '@mui/material/CircularProgress'; // Import a loading spinner
import Box from '@mui/material/Box'; // For layout
import { AppProvider } from './components/chat/AppContext'; // Import the AppProvider
import ChatButton from './components/chat/ChatButton'; // Import the chat button component
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane, faVolumeUp, faHandPaper, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { on } from 'events';
import { ConfirmationProvider } from './contexts/ConfirmationContext';
import LandingPage from './pages/LandingPage';

library.add(faPaperPlane, faVolumeUp, faHandPaper, faMicrophone);
const App: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['armondai']);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const chatContextRef = useRef<any>({}); // Store a ref to hold the latest value

  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000' ? 'http://localhost:5000' : process.env.REACT_APP_API_BASE_URL;
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || 'your-default-client-id';

  const handleLoginSuccess = async (response: any) => {
    const googleToken = response.credential || response.accessToken;

    try {
      const res = await fetch(`${API_BASE_URL}/auth/google/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: googleToken }), // Send Google token to backend
        credentials: 'include', // Include credentials to receive cookies
      });

      if (res.ok) {
        const result = await res.json();
        console.log('Login result:', result);

        // Reload the page to update authentication state
        window.location.href = '/';
      } else {
        const errorData = await res.json();
        console.error('Failed to log in:', res.statusText, errorData);
        alert(`Login failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  const handleLogout = () => {
    googleLogout();
    setUser(null);
    setIsLoggedIn(false);
    removeCookie('armondai', { path: '/' });
    try {
      // Call the backend to clear the cookie
      fetch(`${API_BASE_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
      }).then((res) => {
        if (res.ok) {
          // Successfully logged out
          console.log('Logged out successfully');
          window.location.href = '/'; // Redirect to home or login page
        } else {
          console.error('Failed to log out');
        }
      });
    } catch (err) {
      console.error('Error during logout:', err);
    }

    window.location.href = '/';
  };

  const updateContext = (context: any) => {
    chatContextRef.current = context;
    console.log('Chat context updated:', context);
  };

  const getChatContext = () => {
    console.log('Getting chat context:', chatContextRef.current);
    return chatContextRef.current;
  };
  const handleApplyFilters = useCallback((appliedFilters: any) => {
    console.log('Applied filters:', appliedFilters);
    setFilters(appliedFilters);
  }, []);

  useEffect(() => {
    const token = cookies.armondai; // Get the token from cookies
    if (token) {
      try {
        const decoded = jwtDecode<{
          userId: string;
          email: string;
          firstName: string;
          role: string;
        }>(token);
        console.log('Decoded JWT:', decoded);
        setIsLoggedIn(true);
      } catch (err) {
        console.error('Invalid token:', err);
        setIsLoggedIn(false);
        setUser(null);
        removeCookie('armondai', { path: '/' });
      }
    }
  }, [cookies, removeCookie]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await fetch(`${API_BASE_URL}/auth/status`, {
          method: 'GET',
          credentials: 'include', // Include the HttpOnly cookie with the request
        });

        if (res.ok) {
          const userData = await res.json();
          setUser(userData);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          setUser(null);
        }
      } catch (err) {
        console.error('Error checking authentication', err);
        setIsLoggedIn(false);
        setUser(null);
      } finally {
        setLoading(false); // Authentication check complete
      }
    };

    checkAuth();
  }, [API_BASE_URL]);

  if (loading) {
    // Show a loading spinner while checking authentication
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CookiesProvider>
      <WebSocketProvider>
        <Router>
          <GoogleOAuthProvider clientId={google_client_id}>
            <AppProvider>
              <ConfirmationProvider>
                <Header
                  onOpenFilter={() => setIsFilterOpen(true)}
                  user={user}
                  onLogout={handleLogout}
                  onLoginSuccess={handleLoginSuccess}
                  onApplyFilters={handleApplyFilters}
                  openLoginModal={openLoginModal}
                  setOpenLoginModal={setOpenLoginModal}
                  filters={filters} // Pass the filters here
                />
                <FilterSidebar
                  isOpen={isFilterOpen}
                  onClose={() => setIsFilterOpen(false)}
                  selectedCategory={selectedCategory}
                  filters={filters}
                  onApplyFilters={handleApplyFilters}
                />
                <div className="main-content">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        filters && Object.keys(filters).length === 0 ? (
                          <LandingPage />
                        ) : (
                          <HomePage
                            filters={filters}
                            onLoginRequired={() => setOpenLoginModal(true)}
                            onApplyFilters={handleApplyFilters}
                            onChatContext={updateContext}
                          />
                        )
                      }
                    />
                    {/* All Authenticated Users */}
                    <Route
                      path="/auction/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier', 'LoginUser']} user={user}>
                          <AuctionItemPage onApplyFilters={handleApplyFilters} onChatContext={updateContext} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/category/:category"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier', 'LoginUser']} user={user}>
                          <HomePage
                            filters={filters}
                            onApplyFilters={handleApplyFilters}
                            onLoginRequired={() => setOpenLoginModal(true)}
                            onChatContext={updateContext}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/supplier/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier', 'LoginUser']} user={user}>
                          <HomePage
                            filters={filters}
                            onApplyFilters={handleApplyFilters}
                            onLoginRequired={() => setOpenLoginModal(true)}
                            onChatContext={updateContext}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier', 'LoginUser']} user={user}>
                          <UserProfileSettingsPage />
                        </PrivateRoute>
                      }
                    />

                    {/* Suppliers and Admins */}
                    {user && (user.role === 'Supplier' || user.role === 'Admin') && (
                      <Route
                        path="/add-auction"
                        element={
                          <PrivateRoute requiredRoles={['Admin', 'Supplier']} user={user}>
                            <AddAuctionPage user={user} />
                          </PrivateRoute>
                        }
                      />
                    )}
                    {user && (user.role === 'Supplier' || user.role === 'Admin') && (
                      <Route
                        path="/edit-auction/:id"
                        element={
                          <PrivateRoute requiredRoles={['Admin', 'Supplier']} user={user}>
                            <AddAuctionPage user={user} />
                          </PrivateRoute>
                        }
                      />
                    )}
                    <Route
                      path="/auctions"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier']} user={user}>
                          {user ? <AuctionListPage loginUser={user} /> : <div>Loading...</div>}
                        </PrivateRoute>
                      }
                    />
                    {user && (user.role === 'Supplier' || user.role === 'Admin') && (
                      <Route
                        path="/edit-auction/:id"
                        element={
                          <PrivateRoute requiredRoles={['Admin', 'Supplier']} user={user}>
                            {/* need to support edit auction */}
                            <AddAuctionPage user={user} />
                          </PrivateRoute>
                        }
                      />
                    )}

                    {/* Admin Only */}
                    <Route
                      path="/create-supplier"
                      element={
                        <PrivateRoute requiredRoles={['Admin']} user={user}>
                          <CreateSupplierPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/suppliers"
                      element={
                        <PrivateRoute requiredRoles={['Admin']} user={user}>
                          <SupplierListPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-supplier/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin']} user={user}>
                          <CreateSupplierPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <PrivateRoute requiredRoles={['Admin']} user={user}>
                          <UserListPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-user/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin']} user={user}>
                          {user ? <EditUserPage loginUser={user} /> : <div>Loading...</div>}
                        </PrivateRoute>
                      }
                    />
                    {/* Add a fallback route for undefined paths */}
                    <Route
                      path="*"
                      element={
                        <div style={{ padding: '2rem', textAlign: 'center' }}>
                          <h2>404 - Page Not Found</h2>
                          <p>The page you're looking for doesn't exist.</p>
                        </div>
                      }
                    />
                    <Route path="/LandingPage" element={<LandingPage />} />
                  </Routes>
                </div>
                <ChatButton
                  getChatContext={getChatContext}
                  onOpenFilters={() => setIsFilterOpen(true)}
                  user={user}
                  onApplyFilters={handleApplyFilters}
                  filters={filters} // Pass the filters here
                />{' '}
                {/* Add the chat button component */}
              </ConfirmationProvider>
            </AppProvider>
          </GoogleOAuthProvider>
        </Router>
      </WebSocketProvider>
    </CookiesProvider>
  );
};

export default App;
